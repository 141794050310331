import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
import { MenuComponent } from 'src/app/shared/components/menu/menu.component';
import { AuthService } from '@auth0/auth0-angular';
import { GTMComponentComponent } from 'src/app/shared/components/gtmcomponent/gtmcomponent.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-mobile-nav',
  templateUrl: './mobile-nav.component.html',
  styleUrls: ['./mobile-nav.component.scss']
})
export class MobileNavComponent extends GTMComponentComponent implements OnDestroy, AfterViewInit {

  @ViewChild('menu')
  public menu: MenuComponent;

  who: string | null = null;
  collapseNav = true;
  isSearch = false;
  searchValue = '';
  subscriptions: Subscription[] = [];
  year: number;

  @Input()
  get user(): User {
    return this.self;
  }
  set user(user: User) {
    this.self = user;
    if (this.self !== undefined && this.self !== null) {
      this.isAdministrator = this.self.isAdmin;
    }
  }

  private self: User;
  public isAdministrator = false;

  @Output()
  public navToggle = new EventEmitter<boolean>();

  @Output()
  public logInStateChange = new EventEmitter<boolean>();


  constructor(
    private auth0: AuthService,
    public users: UserService,
    gtm: GoogleTagManagerService
      ) {
      super(gtm);
      this.year = new Date().getFullYear();
      this.self = this.users.authenticatedUser;
      this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
        this.self = u;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.users.profileLoadedObservable$.subscribe((u: User) => {
      this.user = u;
    }));
  }

  signup() {
    this.users.clearProfile();
    this.handle('signup', 'home', 'click', 'Sign Up');

    this.auth0.loginWithRedirect({
      appState: { target: '/my-profile' },
      authorizationParams: {
        screen_hint: 'signUp',
      }
    });
 }

  login() {
    this.users.clearProfile();
    this.handle('login', 'home', 'click', 'Log In');

    this.auth0.loginWithRedirect({
      appState: { target: '/dashboard' },
      authorizationParams: {
        screen_hint: 'login'
      }
    });
  }

  logout(): void {
    this.users.clearProfile();
    this.users.logout();
    this.auth0.logout({
      logoutParams: {
        returnTo: document.location.origin
      }
    });
    this.logInStateChange.emit(false);
  }

  initiateSearch() {

    if (this.searchValue && this.searchValue.length) {
      alert('initiating search for' + this.searchValue);
    }
  }

  toggleNav($event) {
    console.log($event);
    this.collapseNav = !this.collapseNav;
    this.navToggle.emit(this.collapseNav);
  }

  closeNav(): void {
    this.collapseNav = true;
    this.menu.closeMenu();
  }

  showSearch(): void {
    this.isSearch = true;
    this.collapseNav = true;
    this.navToggle.emit(this.collapseNav);
  }
}
