import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, ResumeService, ModalService, FieldOfInterestService, IndustryService, SkillService, StudentService } from '../shared/services';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrl: './library.component.scss'
})
export class LibraryComponent implements OnInit {

  lists: any[] = [];
  activeList: string[] = [];
  activeListName: string;
  pageNumber: number = 1;

  majors: string[] = [];
  careers: string[] = [];
  industries: string[] = [];
  skills: string[] = [];
  actionVerbs: string[] = [];
  avoidedWords: string[] = [];

  working = 0;
  constructor(
    private users: UserService,
    private resumes: ResumeService,
    public modals: ModalService,
    private snackbar: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private fieldOfInterestService:FieldOfInterestService,
    private industryService:IndustryService,
    private skillService: SkillService,
    private studentService: StudentService,
    private resumeService: ResumeService
  ) {

  }

  open(listName: string){
    this.modals.open('library-list');
    const selectedList = this.lists.find(l => l.name === listName);
    this.activeList = selectedList.list;
    this.activeListName = selectedList.name;
    this.pageNumber = 1;
  }

  async ngOnInit() {
    try {
      this.working = 25;
      const [careers, industries, majors, words, skills] = await Promise.all([
        (await this.fieldOfInterestService.get()).map(s => s.name),
        this.industryService.get(),
        this.studentService.getStudies({ majors: true }),
        this.resumeService.getResumeSectionWords(),
        this.skillService.get()
      ]);
    
      this.careers = careers;
      this.industries = industries;
      this.skills = skills;
      this.majors = majors;
      const sectionWords = words

      this.avoidedWords = sectionWords.words.reduce((result, item) => {
        if (!item.strong && item.presentTense) {
          result.push(item.word);
        }
        return result;
      }, []);

      this.actionVerbs = sectionWords.words.reduce((result, item) => {
        if (item.strong && item.presentTense) {
          result.push(item.word);
        }
        return result;
      }, []);

      this.lists.push({ name: 'Action Verbs', list: this.actionVerbs });
      this.lists.push({ name: 'Skills', list: this.skills });
      this.lists.push({ name: 'Careers', list: this.careers });
      this.lists.push({ name: 'Avoided Words', list: this.avoidedWords });
      this.lists.push({ name: 'Majors', list: this.majors });
      this.lists.push({ name: 'Industries', list: this.industries });

    } catch (error) {
      this.snackbar.error({
        message: `There was a error.`,
      });
    } finally {
      this.working = 0;
    }
  }

}
