<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">

        <div class="form edit-section">
            <div class="four-col row">
                <ng-select class="drop-down drop-down-button-blue" [items]="allLanguages" placeholder="Search for a language"
                    [closeOnSelect]="true" (search)="onSearchList('allLanguages', $event)" [virtualScroll]="true"
                    (change)="selectedLanguage($event)">
                </ng-select>
            </div>
        </div>

        <div class="mb-small flex-columns">
            <!-- display Languages -->
            <ng-template ngFor let-l [ngForOf]="currentResumeSection?.languages | sort:'0':true">
                <div class="mb-small languages">
                    <div class="sticker sticker-teal sticker-remove mr-small" (click)="deselectLanguage(l)">
                        <div class="text text-white">
                            {{ l }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>

<div style="display: none;">
    <app-custom-quill #myCustomQuill [initialValue]="currentResumeSection?.description ?? section?.default">
    </app-custom-quill>
</div>
