import { Address } from './address/address.model';
import { AttributeValue } from './attributeValue.model';
import { Availability } from './availability.model';
import { Experience } from './experience.model';
import { IdName } from './idName.model';
import { Resume } from './resume.model';
import { User } from './user.model';

export class Student {

    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public urlName: string;
    public address: Address;
    public phone: string;
    public graduationYear: number;
    public privacy: boolean;
    public logoUrl: string;
    public avatarUrl: string;
    public degree: number;

    public majors: string[];
    public minors: string[];

    public industries: string[];
    public skills: string[];
    public cultures: string[];
    public fieldsOfInterest: IdName[];
    public cities: string[];
    public experiences: Experience[];

    public resumes: Resume[];
    public personalVideoUrl: string;

    public volunteeringExperience: string;
    public workExperience: string;
    public hobbies: string;
    public strengths: string;
    public weaknesses: string;
    public greatestSuccess: string;
    public greatestFailure: string;
    public motivations: string;
    public selfPromotion: string;
    public attributes: AttributeValue[];

    public schoolName: string;
    public schoolUrlName: string;
    public schoolLogoUrl: string;
    public schoolBannerUrl: string;
    public schoolVerified: boolean;
    public availability: Availability;

    public user: User;
    public biography: string;

    public shadowing: boolean;
    public volunteering: boolean;
    public internship: boolean;
    public employment: boolean;
    public other: boolean;
    public coOp: boolean;

    public connecting?: boolean;
    public authorizedUS?: boolean;
    public requireVisa?: boolean;
    public requireOPTCPT?: boolean;
    public employerRecommendations?: boolean;
    public preferredContactMethod?: ContactMethod;

    public partTime: boolean;
    public fullTime: boolean;

    public remote: boolean;
    public inPerson: boolean;
    public locationNoPreference: boolean;

    public employerSizes: number[];
    public profileScore: number;

    public schoolDepartmentId?: number;
    public departmentName?: string;
    public schoolLocation?: string;

}

export enum ContactMethod {
    None = 0,
    Email = 1,
    Phone = 2,
    Message = 3
}