import { SmartWordAnalyzer } from "../services/smartwords.service";
import { UNextAward } from "./uNextAward.model";
import { UNextEducation } from "./uNextEducation.model";
import { UNextExperience } from "./uNextExperience.model";
import { UNextOrganization } from "./uNextOrganization.model";
import { UNextResumeSection } from "./uNextResumeSection.model";

export class Section {
    id: number;
    name: string;
    sectionType: SectionType;
    resumeSections: (UNextAward | UNextEducation | UNextExperience | UNextOrganization | UNextResumeSection)[];
    required: boolean;
    allowMultiple: boolean;
    displayOrder: number;
    analyze: SmartWordAnalyzer;
    default: string | undefined;
    descriptionRequired: boolean;
    tips: string[];
    words: SectionWord[];    //TODO: Add Section Word Model
    completed: boolean | undefined;
    analytics?: ResumeAnalytic | undefined;
    sectionNames?: SectionName[];
    overrideSectionName?: string;
}
export enum SectionType
{
    General = 0,
    Award = 1,
    Education = 2,
    Experience = 3,
    Language = 4,
    Organization = 5,
    Skills = 6
}

export class SectionWord {
    word!: string;
    strong!: boolean;
    presentTense: boolean | undefined;
    replacementWords: string[];
    reason?: string;
}

export class ResumeAnalytic {
    strongWords: number;
    weakWords: number;
    metrics: number;
    actions: number;
    mixedTense: number;
    repetition: number;
    required: number;
    skills: number;
}

export class SectionName {
    sectionId: number;
    schoolId: number;
    name: string;
    id: number;
}