import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Student } from 'src/app/shared/models';
import { StudentService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-add-profile-attribute',
  templateUrl: './student-add-profile-attribute.component.html',
  styleUrls: ['./student-add-profile-attribute.component.scss']
})
export class StudentAddProfileAttributeComponent {

  @Input()
  student: Student;
  @Input()
  applyToStudent = true;
  @Input()
  values: string[] = [];
  @Input()
  attribute: 'culture' | 'industry' | 'fieldofinterest' | 'city';
  @Input()
  placeholder: string;
  @Input()
  headerText: string;
  @Input()
  selectText: string;
  @Input()
  buttonText: string;
  @Input()
  buttonColor: 'orange' | 'blue' | 'purple' | 'teal';
  @Input()
  allowNew = false;
  @Input()
  addText: string | undefined = undefined;
  @Input()
  addOnSelect = false;

  @Output()
  public added: EventEmitter<string> = new EventEmitter<string>();

  working = false;
  isAdded = false;
  public filtered: string[] = [];
  search: string | undefined = undefined;

  constructor(
    private students: StudentService,
    private snackBar: ToastService
  ) {

  }

  async updateProfile(): Promise<void> {
    try {
      await this.addToProfile(this.search);
    } finally {
      this.search = undefined;
    }
  }

  async addNew(value: string): Promise<void> {
    try {
      await this.addToProfile(value);
    } finally {
      this.search = undefined;
    }
  }

  async selectItem(value: string): Promise<void> {
    if (this.addOnSelect) {
      await this.addToProfile(value);
    }
  }

  private async addToProfile(value: string): Promise<void> {
    try {
      this.working = true;
      if (this.applyToStudent) {
        await this.students.addProfile(this.student.urlName, this.attribute, value);
        this.snackBar.success({
          message: `${value} has been added to your profile.`,
        });
      }
      this.added.next(value);
    } catch (error) {
      console.error(error);
      this.snackBar.error({
        title: 'Error',
        message: `There was a problem adding ${value} to your profile.`
      });
    } finally {
      this.working = false;
    }
  }
}
