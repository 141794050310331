export enum ActionType {
    EditResume = 0,
    ArchiveResume = 1,
    SetBasicTemplate = 2,
    SetDefaultTemplate = 3,
    CopyResume = 4,
    UploadJobDescription = 5,
    Preview = 6,
    ViewJobDescription = 7,
    ViewJobSkills = 8,
    DeleteSection = 9,
    DownloadResume = 10
}

export class ActionItem {
    label: string;
    icon: string;
    action: ActionType;

    constructor(label: string, icon: string, action: ActionType) {
        this.label = label;
        this.icon = icon;
        this.action = action;
    }
}

export class ActionMenu {
    public static ResumeMenu: ActionItem[] = [
        new ActionItem('Edit Resume', '../../assets/images/icons/edit.svg', ActionType.EditResume),
        new ActionItem('Archive', '../../assets/images/icons/archive.svg', ActionType.ArchiveResume),
        new ActionItem('Set to default template', '../../assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
        new ActionItem('Set to basic template', '../../assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
        new ActionItem('Copy Resume', '../../assets/images/icons/duplicate.svg', ActionType.CopyResume)
    ];

    public static ResumeTypeDefaultMenu: ActionItem[] = [
        new ActionItem('Set to basic template', '../../assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
    ];

    public static ResumeTypeBasicMenu: ActionItem[] = [
        new ActionItem('Set to default template', '../../assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
    ];

    public static ResumeDetailMenu: ActionItem[] = [
        new ActionItem('Archive', '../../assets/images/icons/archive.svg', ActionType.ArchiveResume),
        new ActionItem('Set to default template', '../../assets/images/icons/duplicate.svg', ActionType.SetDefaultTemplate),
        new ActionItem('Set to basic template', '../../assets/images/icons/duplicate.svg', ActionType.SetBasicTemplate),
        new ActionItem('Copy Resume', '../../assets/images/icons/duplicate.svg', ActionType.CopyResume),
        new ActionItem('View Job Description', '../../assets/images/icons/share.svg', ActionType.UploadJobDescription),
        new ActionItem('Preview Resume', '../../assets/images/icons/duplicate.svg', ActionType.Preview),
    ];
    public static ResumeJobDescription :ActionItem[] = [
        new ActionItem('View Description', '../../assets/images/icons/share.svg', ActionType.ViewJobDescription),
        new ActionItem('View Skills', '../../assets/images/icons/duplicate.svg', ActionType.ViewJobSkills),
    ];

    public static Sections: ActionItem[] = [
        new ActionItem('Delete', '../../assets/images/icons/archive.svg', ActionType.DeleteSection),
    ];
}