<div class="tncwrapper standard-padding">
    <div class="text mb-small">
        Contact Us
    </div>
    <div class="background">
        <section id="table-of-contents">
            <app-table-of-contents [links]="links" [hasHeader]="false"></app-table-of-contents>
        </section>

        <section id="terms-and-conditions">
                <div class="d-flex flex-column">
                    <div class="d-flex mb-2 mb-lg-4">
                        <p class="text">
                            Have a question? Try our <a [routerLink]="['/faqs']">Frequently Asked Questions page.</a>
                            You can also send a message to Unext below and we’ll get back to you
                            as soon as we can.
                        </p>
                    </div>
                    <form class="form" [formGroup]="formGroup" (submit)="onSave()">
                        <label class="form-label text label-1">Full Name:</label>
                        <label class="form-label text label-2">Email Address:</label>
                        <label class="form-label text label-3">Message to Send:</label>
                        <input class="form-input input-1 text" formControlName="name" placeholder="e.g. Jimmy Johnson" />
                        <input class="form-input input-2 text" formControlName="email" placeholder="e.g. jjohnson@email.com" />
                        <textarea class="form-input input-3 text" formControlName="message" placeholder="What would you like to tell us?" rows="6"></textarea>
                        <label class="form-error text message-1" *ngIf="formGroup.touched && name.errors?.required">
                            Name is <strong>required</strong>
                        </label>
                        <label class="form-error text message-2" *ngIf="formGroup.touched && email.errors?.email && !email.errors?.required">
                            Please enter a valid email address
                        </label>
                        <label class="form-error text message-2" *ngIf="formGroup.touched && email.errors?.required">
                            Email is <strong>required</strong>
                        </label>
                        <label class="form-error text message-3" *ngIf="formGroup.touched && message.errors?.required">
                            A message of at least {{ minMsgLength }} characters is <strong>required</strong>
                        </label>
                        <button type="submit" class="button orange outline" [disabled]="formGroup.invalid">Send Message</button>
                    </form>
                </div>
        </section>
    </div>
</div>
