<div>

  <canvas
  baseChart
  [data]="lineChartData"
  [options]="lineChartOptions"
  [type]="lineChartType"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"
  ></canvas>
</div>