<div class="confirm">
    <div class="text heading-4 mb-large">Applying for {{ opportunity?.title }}?</div>
    <form class="form mb-large">
        <div class="flex-columns">
            <div class="flex-rows">
                <div class="flex-columns mb-medium">
                    <!-- <div class="flex-rows mr-large">
                        <label class="form-label mb-small mt-small" for="includeResume">Include Uploaded Resume
                            Attached to my Profile</label>
                        <app-toggle-bar id="includeResume" [on]="includeResume"
                            (toggle)="includeResume = !includeResume" size="small"></app-toggle-bar>
                    </div> -->
                    <div class="flex-rows resumes">
                        <label class="form-label mb-small mt-small" for="includeTranscript">
                            Include a Resume <span *ngIf="resumeId === undefined">*</span>
                            <span class="text text-green" *ngIf="resumeId === 0">It is advisable to have a reseume
                                specific to the position you are applying for.</span>
                        </label>
                        <app-progress-bar [value]="loading">
                            <app-dropdown color="green" [fill]="true" #ddLocation label="Resumes..."
                                [persist]="true" (selected)="selectResume($event)" [fill]="true"
                                [actions]="resumes"></app-dropdown>
                        </app-progress-bar>
                    </div>
                </div>
                <div class="flex-columns mb-medium">
                    <button *ngIf="!coverletter?.length" type="button"
                        class="button button-slim btn-green btn-coverletter mr-small"
                        (click)="modals.open('upload-cover-letter')">
                        Attach
                    </button>
                    <label *ngIf="!coverletter?.length" class="form-label" for="includeTranscript">
                        A cover letter is recommended when applying for this type of opportunity
                    </label>
                    <span *ngIf="coverletter?.length" class="chip chip-green">
                        Cover letter attached...
                    </span>
                </div>
                <div class="flex-columns mb-medium" *ngIf="hasTranscript">
                    <app-toggle-bar class="mr-small" id="includeTranscript" [on]="includeTranscript"
                        (toggle)="includeTranscript = !includeTranscript" size="medium"></app-toggle-bar>
                    <label class="form-label mb-small mt-small" for="includeTranscript">
                        Include Transcript Attached to my Profile</label>
                </div>
                <app-progress-bar [value]="loading">
                    <div class="flex-columns">
                        <button type="button" class="button button-slim btn-green mr-small"
                            [disabled]="resumeId === undefined" (click)="apply()">Apply</button>
                        <button type="button" class="button button-slim btn-orange" (click)="cancel()">Cancel</button>
                    </div>
                </app-progress-bar>
            </div>
        </div>
    </form>
</div>

<app-modal id="upload-cover-letter" size="large">
    <h1 class="mt-large mb-small">Select a coverletter for this opportunity.</h1>
    <h1 class="mb-auto">Your coverletter should be specific to the opportunity you are applying to.</h1>
    <app-upload acceptType="resume" [entityType]="entityType.ApplicationCoverletter" [entityId]="studentOpportunity?.id"
        (filesUploaded)="coverletterUploaded($event, 'upload-cover-letter')">
    </app-upload>
</app-modal>