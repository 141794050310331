import { Component, ViewChild } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { SmartWordService } from 'src/app/shared/services/smartwords.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ModalService, ResumeService, SkillService } from 'src/app/shared/services';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrl: './general.component.scss'
})
export class GeneralComponent extends BaseSectionComponent {

  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  constructor(
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    snackbar: ToastService,
    skills: SkillService,
    router: Router,
    public smartwords: SmartWordService) {

    super(route, fb, modals, resumes, skills, snackbar, router);

    this.forcePresentTense = undefined;
    this.validateStartDate = false;
    this.validateEndDate = false;
    this.validateDescription = true;
    this.maxDescriptionLength = 1200;
    this.setupForm();
  }

  async save(): Promise<any> {

    if (!this.sectionForm?.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {

      // formats it to save
      if (this.sectionForm?.get('description')) {
        this.sectionForm.get('description').setValue(this.quillDescription.textValue);
      }  
  
      let returnData: any = {};
      if (this.sectionForm) {
        Object.keys(this.sectionForm.controls).forEach((key) => {
          returnData[key] = this.sectionForm.get(key).value;
        });
      } else {
        returnData = { ...this.section };
      }
      returnData.resumeSectionId = this.currentResumeSection?.resumeSectionId ?? 0;
      returnData = await this.saveSection(returnData);
      this.quillDescription.initialValue = returnData.description;

      this.snackbar.success({
        message: `${this.section?.name ?? 'section'} updated`
      });

    }
    this.sectionForm.updateValueAndValidity();
  }

  override setupForm(): void {
    super.setupForm();
    this.formSetup = true;
  }

  override updateForm(): void {
    super.updateForm();
    if (this.quillDescription && this.formSetup) {
      this.quillDescription.initialValue = '';  //forces reset
      this.quillDescription.initialValue = this.sectionForm.get('description').value;
    }
  }


}
