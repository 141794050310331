import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-list-scroll',
  templateUrl: './list-scroll.component.html',
  styleUrls: ['./list-scroll.component.scss']
})
export class ListScrollComponent implements AfterViewInit, OnInit {

  @ViewChild('body', {static: false})
  body: ElementRef<HTMLElement>;

  scroll = false;
  scrollSize = 420;

  ngOnInit(): void {
    setTimeout(() => {
      this.ngAfterViewInit();
    }, 10000)
  }  

  ngAfterViewInit(): void {
    if (this.body.nativeElement.children.length) {
      if (this.body.nativeElement.clientWidth > this.body.nativeElement.children[0].clientWidth) {
        this.scroll = true;
      }
    }
  }

  back(): void {
    this.body.nativeElement.scrollBy({ left: -this.scrollSize, behavior: 'smooth'});
  }
  forward(): void {
    this.body.nativeElement.scrollBy({ left: this.scrollSize, behavior: 'smooth'});
  }
}
