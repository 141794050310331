<div class="standard-padding prospects">
        <div class="title-container">
        <div class="text heading-2 mb-medium">
            Prospects
        </div>
        <app-info-modal [audience]="'Employers'" [section]="'Prospects'" [item]="'Prospects'"></app-info-modal>
    </div>

    <app-progress-bar [splashScreen]="true" [value]="working"></app-progress-bar>

    <div class="rounded-corners mb-medium by-opportunity">
        <div class="text heading-4 mb-small">
            Filter
        </div>
        <div class="filter-section">
            <div class="search-input form">
                <input id="search" type="text" placeholder="Search"
                        class="form-input form-input-rounded form-input-green mb-small" [(ngModel)]="search" />
                
                <button class="button dark-green mb-small" (click)="loadProspects()">Search</button>
            </div>

            <app-dropdown color="white"
            label="Select an opportunity"
            [persist]="true"
            [fill]="true"
            (selected)="selectOpportunity($event)"
            [actions]="listOpportunities | sort:'label':true"
            [value]="selectedOpportunity?.id"></app-dropdown>

            <app-dropdown color="white"
            label="Select a school"
            [persist]="true"
            [fill]="true"
            (selected)="selectSchool($event)"
            [actions]="listSchools | sort:'label':true"
            [value]="selectedSchool"></app-dropdown>
        </div>
    </div>
    <div class="rounded-corners mb-medium">
        <div class="mb-small recents">
            <div *ngIf="selectedOpportunity && selectedPropsects?.length === 0">
                No one has applied for this position
            </div>
            <div class="prospects-section">
                <app-employer-prospect-card class="mr-small mb-small" [prospect]="prospect" *ngFor="let prospect of selectedPropsects"></app-employer-prospect-card>
            </div>
        </div>
    </div>

    <!-- <div class="rounded-corners mb-medium search">
        <div class="mb-small">
            <div class="text heading-4 mb-small">
                All Prospects
            </div>

        </div>

    </div> -->
</div>
