<div class="container standard-padding">
    <section class="heading single-row">
        <div class="text text-black xl">
            About
        </div>
        <img alt="Unext" title="Unext" [src]="cdn.toCDN('images/logos/unext-overlap.png')"
            [srcset]="cdn.toCDN('images/logos/unext-overlap@2x.png 2x') + ', ' + cdn.toCDN('images/logos/unext-overlap@3x.png 3x')" />
    </section>
    <section class="single-row">
        <div class="headline">
            <p class="text">
                Our mission is to help college students learn about careers that relate to their major.
            </p>
            <p class="text" style="font-size: 18px">
                We connect them to first-hand experiences such as job shadows, internships, jobs, and other opportunities.
            </p>
        </div>
        <img class="rocket" [src]="cdn.toCDN('images/svg/rocket.svg')" />
    </section>
    <section class="single-row">
        <div class="stat-box">
            <div class="text text-green-muted">75%</div>
            <div>
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap.svg')">
                <img [src]="cdn.toCDN('images/svg/graduation-cap-grey.svg')">
            </div>
            <div class="text">
                of college students change their major at least <span class="text text-green text-upper">once.</span>
            </div>
        </div>
        <div class="stat-box">
            <div class="text text-green-muted">50-80%</div>
            <div>
                <img [src]="cdn.toCDN('images/svg/frowny-face.svg')">
            </div>
            <div class="text">
                of Americans think their job is <span class="text text-green text-upper">unrewarding.</span>
            </div>
        </div>
        <div class="stat-box">
            <div class="text">out of all college graduates</div>
            <div class="text text-green-muted">43%</div>
            <div class="text">
                are <span class="text text-green text-upper">underemployed</span> for their first job out of school.
            </div>
            <div class="text" style="font-size: 18px">
                This means they are overqualified for the position they hold.
            </div>
        </div>
    </section>
    <section>
        <p class="text">
            We help college students (freshman through seniors) get connected to short-term and long-term opportunities in the professional world.
        </p>
        <p class="text">
            We offer every student an equal opportunity to learn about careers and majors through these first-hand experiences.
        </p>
        <p class="text">
            This helps students gain a better understanding about their major/field of interest before committing to an
            internship or job. Ultimately, these connections and experiences students have will boost their confidence
            when pursuing a major, increasing the probability that students will be satisfied with their careers after
            college. Unext aims to increase the amount of connections between students and employers and help employers
            recruit/identify talent that will best fit their company. Not every student wants to work for a major
            corporation, so students will be able to connect with small and large employers on our platform. Students
            will learn about different company cultures to help decide which businesses will match their personality.
            Explore as many environments as possible through Unext!
        </p>
    </section>

</div>
