<section id="candidates">
    <div class="text heading-3 mb-medium">
        New candidates in your area
    </div>
    <app-progress-bar [splashScreen]="false" [value]="working"></app-progress-bar>
    <div class="candidates">
        <a *ngFor="let c of candidates" class="tile" [routerLink]="['/dashboard']" [queryParams]="c.queryParams">
            <div class="image-container mb-small">
                <img [src]="cdn.toCDN('images/icons/location-icon.png')"
                [srcset]="cdn.toCDN('images/icons/location-icon@2x.png 2x') + ', ' + cdn.toCDN('images/icons/location-icon@3x.png 3x')"
                [alt]="c.location" />
            </div>
            <div class="text text-normal mb-small text-center">
                {{ c.location }}
            </div>
            <div class="text text-small text-green mb-small text-center">
                {{ c.students }} students
            </div>
            <div *ngIf="c.majors.length" class="text text-small text-center majors">
                {{ c.majors }}, and other majors
            </div>
        </a>
        <a class="tile green no-flex" [routerLink]="['/dashboard']">
            <img class="image-right" [src]="cdn.toCDN('images/icons/go.png')" />
            <p class="text text-normal text-white">
                Login to see more candidates
            </p>
        </a>
    </div>
</section>