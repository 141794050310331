<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">

        <!-- Awards Section -->
        <div *ngIf="section.name === 'Awards & Accomplishments'" class="form edit-section">
            <div class="four-col row">
                <label for="awardName" class="form-label text text-black">
                    Award/Accomplishment Name
                </label>
                <input name="awardName" type="text" formControlName="awardName"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="three-col row">
                <label for="entity" class="form-label text text-black">
                    Entity/Organization
                </label>
                <input name="entity" type="text" formControlName="awarder"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="date" class="form-label text text-black">
                    Date Aquired
                </label>
                <input name="date" type="date" formControlName="startDate"
                    class="form-input border border-teal text form-input-100" />
            </div>
        </div>

        <!-- Certs -->
        <div *ngIf="section.name === 'Certifications'" class="form edit-section">
            <div class="four-col row">
                <label for="awardName" class="form-label text text-black">
                    Certification Name
                </label>
                <ng-select id="awardName" formControlName="awardName" class="drop-down drop-down-button-blue"
                    [items]="certifications | sort:'':true" placeholder="Search for a Certification" [closeOnSelect]="true"
                    (search)="onSearchList('certifications', $event)" [virtualScroll]="true"
                    addTagText="This is my certification" [addTag]="addCertification">
                </ng-select>

                <!-- <ng-select formControlName="awardName" class="drop-down drop-down-button-blue"
                    [items]="certifications | sort:'name':'asc'" bindLabel="name" bindValue="name"
                    placeholder="Select a certification" [closeOnSelect]="true" [virtualScroll]="true"
                    addTagText="This is my certification" [addTag]="addCertification">
                </ng-select> -->
                <!-- <input name="cert" type="text" formControlName="awardName"
                class="form-input form-input-rounded form-input-100 text" /> -->
            </div>

            <div class="three-col row">
                <label for="entity" class="form-label text text-black">
                    Entity/Organization
                </label>
                <input name="entity" type="text" formControlName="awarder"
                    class="form-input border border-teal text form-input-100" />
            </div>

            <div class="two-col row">
                <label for="date" class="form-label text text-black">
                    Date Aquired
                </label>
                <input name="date" type="date" formControlName="startDate"
                    class="form-input border border-teal text form-input-100" />
            </div>
        </div>
        <div class="form edit-section">

            <div class="six-col">
                <label class="form-label text text-black">
                    Description
                </label>
                <div style="height: 350px;">
                    <app-custom-quill [initialValue]="currentResumeSection?.description ?? section?.default"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>
        <div class="form edit-section mt-large">
            <div class="three-col">
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['awardName'].errors?.required">
                    Name is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.controls['awarder'].errors?.required">
                    Entity is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('required')">
                    Date is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && sectionForm.get('startDate').hasError('invalidDate')">
                    Date is invalid
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>
            </div>
            <div class="three-col row">
                <div class="mr-auto">
                    <button type="button" (click)="delete()">
                        Delete
                    </button>
                </div>
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid">
                        Save Section
                    </button>
                </div>
            </div>
        </div>
    </form>
    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>