import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AttributeValue, EntityType, Resume, ResumeType, UNextResume } from 'src/app/shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import { User } from 'src/app/shared/models/user.model';
import { ModalService, ResumeService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { StudentService } from 'src/app/shared/services/student.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { ActionType } from 'src/app/shared/models/actionmenu.model';

@Component({
  selector: 'app-student-resumes',
  templateUrl: './student-resumes.component.html',
  styleUrls: ['./student-resumes.component.scss']
})
export class StudentResumesComponent implements OnInit {

  // student: Student;
  user: User;
  loading = 0;
  selectedResume: UNextResume;
  selectedSection: AttributeValue;
  resumeType: ResumeType;
  entityType: EntityType;
  showInactive = false;
  uresumes: UNextResume[] = [];

  resumeBuilderFormGroup = new FormGroup({
    resumeName: new FormControl(''),
    jobDescription: new FormControl('')
  });

  public get resumeTypes(): typeof ResumeType {
    return ResumeType;
  }

  constructor(
    private users: UserService,
    private students: StudentService,
    private snackBar: ToastService,
    private modals: ModalService,
    private resumes: ResumeService,
    public cdn: CDNService,
    private router: Router,
  ) {
    this.user = this.users.authenticatedUser;
  }

  async ngOnInit() {
    
    try {
      this.loading = 20;
      this.uresumes = await this.resumes.getResumes();
      this.uresumes = new ArraySortPipe().transform(this.uresumes, 'modified', false);
    } finally {
      this.loading = 0;
    }
  }

  view(resume: Resume): void {
    window.open(this.cdn.toCDN(resume.url, true), '_new');
  }

  editResume(unextResume: UNextResume): void {
    if (!this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : this.user.isStaff ? this.user.staff.urlName : 'unext', 'resume-builder', encodeURIComponent(unextResume.name)])) {
      console.log('could not navigate');
    }
  }

  //TODO: expand for UNext resume
  remove(resume: UNextResume, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.selectedResume = resume;
    this.modals.open('confirm-delete');
  }

  show(resume: UNextResume, e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.selectedResume = resume;
  }

  async confirmDelete(id: string): Promise<void> {
    this.modals.close(id);

    if (this.selectedResume.resumeType === ResumeType.Unext) {
      await this.confirmDeleteUnextResume();
    } else {
      try {
        this.loading = 20
        if (await this.resumes.deleteResume(this.selectedResume.id)) {

          for (let i = 0; i < this.uresumes.length; ++i) {
            if (this.uresumes[i].id === this.selectedResume.id) {
              this.uresumes.splice(i, 1);
              break;
            }
          }
        }
      } catch (error) {
        this.snackBar.error({ message: error?.error ?? error });
      } finally {
        this.loading = 0;
      }
    }
  }

  async confirmDeleteUnextResume(): Promise<void> {
    try {
      this.loading = 20;
      await this.resumes.deleteResume(this.selectedResume.id);
      this.selectedResume.active = false;
      this.toggleInactive(this.showInactive);
    } catch (error) {
      this.snackBar.error({ message: error?.error ?? error });
    } finally {
      this.loading = 0;
    }
  }

  cancelDelete(id: string): void {
    this.selectedResume = null;
    this.modals.close(id);
  }

  addResume(id: string, type: ResumeType): void {
    this.resumeType = type;
    this.entityType = EntityType.Unknown;
    switch (type) {
      case ResumeType.CoverLetter:
        this.entityType = EntityType.CoverLetter;
        break;
      case ResumeType.Resume:
        this.entityType = EntityType.Resume;
        break;
      case ResumeType.Transcript:
        this.entityType = EntityType.Transcript;
        break;
      case ResumeType.VideoResume:
        this.entityType = EntityType.Video;
        break;
    }
    this.openModal(id);
  }
  openCustom(value: AttributeValue, id: string) {
    this.selectedSection = value;
    this.modals.open(id);
  }
  openModal(id: string): void {
    this.modals.open(id);
  }

  async savedCustomSection(added: boolean, id: string) {
    if (added) {
      this.snackBar.success({
        message: 'Custom section updated...'
      });
      // await this.loadStudent();
    }
    this.modals.close(id);
    this.selectedSection = null;
  }

  async uploadAndGo(files: string[], id: string): Promise<void> {
    if (files?.length) {
      await this.filesUploaded(true, id);
      const newResume = new ArraySortPipe().transform(this.uresumes, 'modified', false)[0];
      this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', encodeURIComponent(newResume.name)]);
    } else {
      this.modals.close(id);
    }
  }

  async filesUploaded(success: boolean, id: string): Promise<void> {
    if (success ?? true) {
      this.modals.close(id);
      this.loading = 20;
      this.uresumes = await this.resumes.getResumes();
      this.loading = 0;
    }
  }

  close(id: string): void {
    this.modals.close(id);
  }

  async startResumeBuilder(modalId: string): Promise<void> {

    this.close(modalId);
    try {
      this.loading = 20;
      const newResume: UNextResume = {
        name: this.resumeBuilderFormGroup.get('resumeName').value,
        email: this.user?.email ?? this.user.email,
        phone: this.user?.phone ?? this.user.phone,
        address: this.user ? `${this.user?.location?.address?.city?.name} ${this.user?.location?.address.city.state.name}` : '',
        fullName: `${this.user?.firstName } ${this.user?.lastName }`,
        links: [],
        id: 0,
        sections: null,
        resumeType: ResumeType.Unext,
        jobDescription: { id: 0, fullText: this.resumeBuilderFormGroup.get('jobDescription').value },
        documentTypeId: 0
      };

      const response = await this.resumes.createNewResume(newResume);

      if (response.id) {
        this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', encodeURIComponent(newResume.name)]);
      } else {
        this.snackBar.error({
          message: 'Error starting resume builder'
        });
        this.openModal(modalId);
      }
    } finally {
      this.loading = 0;
    }
  }

  toggleInactive(value: boolean): void {
    this.showInactive = value;
  }

  hasInactive(): boolean {
    return (this.uresumes?.filter(r => !r.active)?.length ?? 0) > 0;
  }

  async handleAction(input: any, resume?: UNextResume) {
    if (input == ActionType.EditResume) {
      this.editResume(resume);
    } else if (input == ActionType.ArchiveResume) {
      this.beginArchive(resume.id, 'begin-archive');
    }
    else if (input == ActionType.SetDefaultTemplate) {
      resume.resumeTemplateTypeId = 1;
      await this.resumes.updateResume(resume.id, resume);
      this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', resume.name, 'preview', 'base']);
    }
    else if (input == ActionType.SetBasicTemplate) {
      resume.resumeTemplateTypeId = 2;
      await this.resumes.updateResume(resume.id, resume);
      this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', resume.name, 'preview', 'base']);
    } else if (input === ActionType.CopyResume) {
      try {
        this.loading = 20;
        this.selectedResume = await this.resumes.cloneResume(resume.id);
      } finally {
        this.loading = 0;
        this.router.navigate(['/students', this.user.isStudent ? this.user.student.urlName : 'staff', 'resume-builder', this.selectedResume.name]);
      }

    }
  }

  archiveId: number | undefined = undefined;

  beginArchive(id: number, name: string) {
    this.archiveId = id;
    this.modals.open(name);
  }

  cancelArchive(name: string) {
    this.archiveId = undefined;
    this.modals.close(name);
  }

  async archive(name: string): Promise<void> {
    this.modals.close(name);

    try {
      this.loading = 20;
      await this.resumes.deleteResume(this.archiveId);
      const r = this.uresumes.find(r => r.id === this.archiveId);
      if (r) {
        r.active = false;
      }
      this.toggleInactive(this.showInactive);
    } finally {
      this.archiveId = undefined;
      this.loading = 0;
      this.router.navigate(['/my-profile', 'resume']);
    }
  }

}
