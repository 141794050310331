<div class="nav-header">
    <app-logo [loggedIn]="!!user" size="small"></app-logo>
    <app-menu #menu (openStateChange)="toggleNav($event)"></app-menu>
</div>
<div class="nav-spacer"></div>
<nav class="nav-items" [ngClass]="{ 'active': !this.collapseNav }" (click)="closeNav()">
    <div class="nav-item" *ngIf="!user">
        <a [routerLink]="['/for-students']" routerLinkActive="active" class="mx-auto">For Students</a>
    </div>
    <div class="nav-item" *ngIf="!user">
        <a [routerLink]="['/for-employers']" routerLinkActive="active" class="mx-auto">For Employers</a>
    </div>

    <div class="nav-item" *ngIf="!!user">
        <a [routerLink]="['/dashboard']" routerLinkActive="active" class="mx-auto">
            Dashboard
        </a>
    </div>
    <div class="nav-item" *ngIf="user?.isStudent">
        <a [routerLink]="['my-opportunities']" routerLinkActive="active" class="mx-auto">
            Opportunities
        </a>
    </div>
    <div class="nav-item" *ngIf="user?.isEmployer">
        <a [routerLink]="['employers', user?.employee?.employerUrlName, 'opportunity']" routerLinkActive="active" class="mx-auto">
            Opportunities
        </a>
    </div>
    <!-- <div class="nav-item" *ngIf="user?.isEmployer">
        <a [routerLink]="['/my-prospects']" routerLinkActive="active" class="mx-auto">
            Prospects
        </a>
    </div> -->
    <!-- <div class="nav-item" *ngIf="user?.isStudent">
        <a [routerLink]="['/my-favorites']" routerLinkActive="active" class="mx-auto">
            Favorites
        </a>
    </div> -->
    <div class="nav-item" *ngIf="user?.isEmployer">
        <a [routerLink]="['/employers', user?.employee?.employer?.urlName , 'edit']" routerLinkActive="active" class="mx-auto">Edit Company</a>
    </div>
    <div class="nav-item" *ngIf="!!user">
        <a [routerLink]="['/my-profile']" routerLinkActive="active" class="mx-auto">
            Edit Profile
        </a>
    </div>
    <div class="nav-item" *ngIf="user?.isStudent">
        <a [routerLink]="['/my-profile/resume']" routerLinkActive="active" class="mx-auto">
            Edit Resume
        </a>
    </div>
    <div class="nav-item" *ngIf="!!user">
        <a [routerLink]="['/messages']" routerLinkActive="active" class="mx-auto">
            Messages
        </a>
    </div>
    <div class="nav-item">
        <a [routerLink]="['/contact-us']" routerLinkActive="active" class="mx-auto">Contact Unext</a>
    </div>
    <div class="nav-item">
        <a [routerLink]="['/faqs']" routerLinkActive="active" class="mx-auto">FAQs & Help</a>
    </div>
    <div class="nav-item" *ngIf="!user">
        <a [routerLink]="['/request-demo']" class="button dark-green btn-square mt-auto mb-auto">Request Demo</a>
    </div>

    <div class="nav-buttons" *ngIf="!user">
        <div class="nav-button button btn-blue btn-square" (click)="login()">
            Login
        </div>
        <div class="nav-button button orange outline btn-square" (click)="signup()">
            Sign up
        </div>
    </div>
    <div class="nav-item">
        <a class="mx-auto" (click)="logout()">Sign Out</a>
    </div>

    <div class="nav-footer text-center">
        &amp;{{ year }} Unext. All rights reserved.
    </div>
</nav>
