{
  "name": "unext-front-end",
  "version": "0.23.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 3003 --ssl --ssl-key .\\localhost.key --ssl-cert .\\localhost.crt",
    "build": "ng build",
    "build:stage": "ng lint && ng build --configuration=stage",
    "build:prod": "ng lint && ng build --configuration=production",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "ng e2e",
    "mac:gencert": "openssl genrsa -aes256 -passout pass:Unext321 -out localhost.pass.key 4096 && openssl rsa -passin pass:Unext321 -in localhost.pass.key -out localhost.key && rm localhost.pass.key && openssl req -new -subj \"/CN=Unext Localhost\" -addext \"subjectAltName = DNS:localhost\" -key localhost.key -out localhost.csr && openssl x509 -req -sha256 -days 365 -in localhost.csr -signkey localhost.key -out localhost.crt",
    "start-mac": "ng serve --port 3003 --ssl",
    "start-mac-stage": "ng serve --port 3003 --ssl --ssl-key .\\localhost.key --ssl-cert .\\localhost.crt --configuration=stage",
    "start-mac-production": "ng serve --port 3003 --ssl --ssl-key .\\localhost.key --ssl-cert .\\localhost.crt --configuration=production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.6",
    "@angular/common": "^18.2.6",
    "@angular/compiler": "^18.2.6",
    "@angular/core": "^18.2.6",
    "@angular/forms": "^18.2.6",
    "@angular/platform-browser": "^18.2.6",
    "@angular/platform-browser-dynamic": "^18.2.6",
    "@angular/router": "^18.2.6",
    "@angular/service-worker": "^18.2.6",
    "@auth0/auth0-angular": "^2.2.1",
    "@fortawesome/angular-fontawesome": "^0.10.1",
    "@fortawesome/fontawesome-svg-core": "~1.2.36",
    "@fortawesome/free-brands-svg-icons": "^5.15.4",
    "@fortawesome/free-regular-svg-icons": "^5.15.4",
    "@fortawesome/free-solid-svg-icons": "^5.15.4",
    "@ng-select/ng-select": "^13.8.1",
    "@popperjs/core": "^2.11.6",
    "@stripe/stripe-js": "^1.50.0",
    "angular": "^1.8.3",
    "angular-animate": "^1.8.3",
    "angular-aria": "^1.8.3",
    "angular-google-tag-manager": "1.10.0",
    "angular-messages": "^1.8.3",
    "angular-sanitize": "^1.8.3",
    "bootstrap": "^5.2.2",
    "chart.js": "^4.4.4",
    "cookieconsent": "^3.1.1",
    "font-awesome": "^4.7.0",
    "guid-typescript": "^1.0.9",
    "hammerjs": "^2.0.8",
    "jquery": "^3.6.1",
    "jwt-decode": "^4.0.0",
    "moment": "^2.29.4",
    "morgan": "^1.10.0",
    "ng-chartjs": "^0.2.8",
    "ng2-charts": "^6.0.1",
    "ngx-cookieconsent": "^4.0.1",
    "ngx-pagination": "^6.0.3",
    "ngx-quill": "^26.0.8",
    "normalize.css": "^8.0.1",
    "popper.js": "^1.16.1",
    "rxjs": "^7.5.7",
    "slick-carousel": "^1.8.1",
    "toastr": "^2.1.4",
    "tslib": "^2.3.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.6",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.6",
    "@angular/compiler-cli": "^18.2.6",
    "@angular/localize": "^18.2.6",
    "@types/jasmine": "^4.3.0",
    "@types/jasminewd2": "^2.0.10",
    "@types/node": "^20.9.0",
    "@types/toastr": "^2.1.43",
    "@typescript-eslint/eslint-plugin": "^6.20.0",
    "@typescript-eslint/parser": "^6.20.0",
    "eslint": "^8.47.0",
    "eslint-config-standard-with-typescript": "^43.0.1",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-n": "^16.3.1",
    "eslint-plugin-promise": "^6.1.1",
    "jasmine-core": "^4.4.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "^3.1.1",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "protractor": "^7.0.0",
    "ts-node": "^10.9.1",
    "typescript": "^5.5.4"
  },
  "overrides": {
    "piscina": "4.5.1"
  }
}
