import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Audience, Employee, Employer, EmployerFieldOfInterest, EntityType, OpportunityType, User } from 'src/app/shared/models';
import { SubscriptionOptions } from 'src/app/shared/models/subscriptionOptions.model';
import { ArrayMatchSortPipe } from 'src/app/shared/pipes';
import { AudienceService, EmployerService, FieldOfInterestService,
  IndustryService, LocationService, ModalService, OpportunityService, ResumeService, SchoolService, SizeService, SkillService, StudyService, UploadService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { DegreeService } from 'src/app/shared/services/degree.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ReadonlyQuillConfiguration } from 'src/app/static/quill/quilll.config';

@Component({
  selector: 'app-employer-profile-edit',
  templateUrl: './app-employer-profile-edit.component.html',
  styleUrls: ['./app-employer-profile-edit.component.scss']
})
export class EmployerProfileEditComponent {

  formGroup: FormGroup;
  youtubeFormGroup: FormGroup;
  audienceForm: FormGroup;
  employeeFormGroup: FormGroup;
  working = 0;
  urlName: string;
  employer: Employer;
  user: User;
  audiences: Audience[] = [];
  allIndustries: string[] = [];
  employees: User[] = [];
  subscriptions: any[] = [];
  selectedAudience: Audience;
  allAddresses: string[] = [];
  allSkills: string[] = [];
  allStudies: string[] = [];
  allSchools: string[] = [];
  allInterests: DropDownOptions[] = [];
  allCertifications: DropDownOptions[] = [];
  allLocations: string[] = [];
  allStates: DropDownOptions[] = [];
  stripePlans: SubscriptionOptions[] = [];
  selectedState = '';
  newCity = '';
  allSizes: any[];
  employerTypes: DropDownOptions[] = [];
  
  selectedField: any;
  fieldsControl = new FormControl();

  quillConfiguration = ReadonlyQuillConfiguration;

  entityType = EntityType;
  stripeUrl: string | undefined;

  locations: DropDownOptions[];
  employerLocations: DropDownOptions[];
  positions: DropDownOptions[];
  employee: Employee;

  opportunityTypes = OpportunityType;
  candidateTypes: DropDownOptions[];
  opportunities: any;

  oppTypes: DropDownOptions[];


  constructor(
    private users: UserService,
    private modals: ModalService,
    private snackbar: ToastService,
    private employerService: EmployerService,
    private industryService: IndustryService,
    private audienceService: AudienceService,
    private locationService: LocationService,
    private skillService: SkillService,
    private studyService: StudyService,
    private schoolSerivce: SchoolService,
    private interestService: FieldOfInterestService,
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    public cdn: CDNService,
    private upload: UploadService,
    private route: ActivatedRoute,
    private sizeService: SizeService,
    private router: Router,
    private degrees: DegreeService,
    private oppService: OpportunityService,
    private resumeService: ResumeService
  ) {
    this.user = this.users.authenticatedUser;

    this.subscriptions.push(this.route.params.subscribe(async (params) => {
      this.urlName = params.urlName;
      await this.loadEmployer();
    }));

    this.candidateTypes = this.degrees.getDegreeDropdownValues();
    this.oppTypes = this.oppService.getOpportunityTypesDropdownValues();
    this.employerTypes = this.employerService.getEmployerTypesDropDownValues();
  }

  async loadEmployer(): Promise<void> {
    this.working = 0;
    try {
      this.working += 10;
      this.employer = await this.employerService.getEmployer(this.urlName);
      this.locations = (await this.employerService.getEmployerLocations(this.employer.urlName)).map(s => ({
        label: s.name,
        value: s.id
      }));
      this.employerLocations = [{label: 'All Locations', value: 0}, ...this.locations, ];
      
      this.setInterestLocations(this.employer.employerFieldOfInterests);

      this.opportunities = await this.employerService.getEmployerOpportunities(this.employer.urlName);
      this.allAddresses = this.employer.addresses;
      this.working += 10;
      this.employees = await this.employerService.getEmployees(this.urlName);
      this.working += 10;
      this.allLocations = await this.locationService.getCities();
      this.working += 10;
      this.subscriptions = await this.employerService.getSubscription(this.urlName);
      this.working += 10;
      this.allIndustries = await this.industryService.get();
      this.working += 10;
      this.audiences = await this.audienceService.get(this.urlName);
      this.working += 10;
      this.allSkills = await this.skillService.get();
      this.working += 10;
      this.allStudies = await this.studyService.get();
      this.working += 10;
      this.allSchools = await this.schoolSerivce.getSchoolNames();
      this.working += 10;
      this.allInterests  =(await this.interestService.get())
      .map(s => ({
        label: s.name,
        value: s.id
      }));

      this.allCertifications = (await this.resumeService.getCertifications()) .map(s => ({
        label: s.name,
        value: s.id
      }));
       
      this.working += 10;
      this.allStates = (await this.employerService.getStates())
        .map(s => ({
          label: s,
          value: s
        }));

      this.allSizes = (await this.sizeService.get()).map(ss => { return { label: ss.value, value: ss.id } });
      const plans = await this.subscriptionService.getSubscriptionDetails();
      this.stripePlans = plans.filter(p => p.name.startsWith(this.employer?.size));
      this.createForm();
     // this.createFieldForm();


    } catch (error) {
      this.snackbar.error({
        message: 'There was a problem loading your company information',
      });
    } finally {
      this.working = 0;
    }
  }

  createForm(): void {
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

    const s = this.allSizes.find(p => p.value == this.employer.sizeId);

    this.formGroup = this.fb.group({
      CompanyName: new FormControl(this.employer.name, [Validators.required]),
      URL: new FormControl(this.employer.domain, [Validators.pattern(urlRegex)]),
      EmailDomain: new FormControl(this.employer.emailDomains.join(',')),
      ContactEmail: new FormControl(this.employer.contactEmail, [Validators.email]),
      CompanyBio: new FormControl(this.employer.description),
      CompanyHistory: new FormControl(this.employer.history),
      CompanyValues: new FormControl(this.employer.values),
      CompanySize: new FormControl(s.label),
      ShadowsAvailable: new FormControl(this.employer.shadowsAvailable),
      RecruitmentPortal: new FormControl(this.employer?.recruitmentPortal),
      ExternalCoopLink: new FormControl(this.employer?.externalCoopLink),
      ExternalEmploymentLink: new FormControl(this.employer?.externalEmploymentLink),
      ExternalInternshipLink: new FormControl(this.employer?.externalInternshipLink),
      ExternalVolunteerLink: new FormControl(this.employer?.externalVolunteerLink),
      EmployerType: new FormControl(this.employer.employerTypeId ? this.employerTypes.find(x => x.value == this.employer?.employerTypeId).label : 'Private')
    });

    this.youtubeFormGroup = this.fb.group({
      YouTubeUrl: new FormControl(this.employer.youTubeUrl, [])
    })

  }

  getFormControl(name: string): FormControl {
    return this.formGroup.controls[name] as FormControl;
  }
  companyName(): FormControl {
    return this.getFormControl('CompanyName');
  }
  url(): FormControl {
    return this.getFormControl('URL');
  }
  emailDomain(): FormControl {
    return this.getFormControl('EmailDomain');
  }
  contactEmail(): FormControl {
    return this.getFormControl('ContactEmail');
  }
  companyBio(): FormControl {
    return this.getFormControl('CompanyBio');
  }
  companyHistory(): FormControl {
    return this.getFormControl('CompanyHistory');
  }
  companyValues(): FormControl {
    return this.getFormControl('CompanyValues');
  }
  companySize(): FormControl {
    return this.getFormControl('CompanySize');
  }
  shadowsAvailable(): FormControl {
    return this.getFormControl('ShadowsAvailable');
  }
  recruitmentPortal(): FormControl {
    return this.getFormControl('RecruitmentPortal');
  }

  externalCoopLink(): FormControl {
    return this.getFormControl('ExternalCoopLink');
  }

  externalEmploymentLink(): FormControl {
    return this.getFormControl('ExternalEmploymentLink');
  }

  externalInternshipLink(): FormControl {
    return this.getFormControl('ExternalInternshipLink');
  }

  externalVolunteerLink(): FormControl {
    return this.getFormControl('ExternalVolunteerLink');
  }


  openModal(id: string, type?: string): void {
    if(type){
      this.uploadType = type;
    }
    this.modals.open(id);
  }

  close(id: string): void {
    this.modals.close(id);
  }

  selectIndustry(name: string): void {
    this.employer.industries.push(name);
  }
  
  async removeIndustry(name: string): Promise<void> {
    try {
      this.working = 25;
      await this.employerService.removeProfile(this.employer.urlName, 'industry', name);
      this.employer.industries = this.employer.industries.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async selectLocation(name: string): Promise<void> {
    if (name?.length) {
      try {
        this.working += 10;
        this.employer.addresses.push(name);
      } catch (error) {
        this.snackbar.error({
          message: 'There was a problem loading your company information',
        });
      } finally {
        this.working = 0;
      }
    }
  }

  selectState(value: DropDownOptions): void {
    this.selectedState = value.value;
  }
  async addCity(): Promise<void> {
    try {
      this.working = 20;
      this.employerService.addProfile(this.employer.urlName, 'city', `${this.newCity}, ${this.selectedState}`);
      this.employer.addresses.push(`${this.newCity}, ${this.selectedState}`);
      this.snackbar.success({
        message: `$${this.newCity}, ${this.selectedState} was added to your company profile.`
      });
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem adding ${this.newCity}, ${this.selectedState}`
      });
    } finally {
      this.working = 0;
    }
  }

  async removeCity(city: string): Promise<void> {
    try {
      this.working = 25;
      await this.employerService.removeProfile(this.employer.urlName, 'city', city);
      this.employer.addresses = this.employer.addresses.filter(a => a !== city);
      this.snackbar.success({
        message: `${ city } was removed from your company profile.`
      });
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${city} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeSchool(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.schools?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'school', name, this.selectedAudience.name);
      }
      this.selectedAudience.schools = this.selectedAudience.schools.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeMinor(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.minors?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'minor', name, this.selectedAudience.name);
      }
      this.selectedAudience.minors = this.selectedAudience.minors.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeMajor(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.majors?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'major', name, this.selectedAudience.name);
      }
      this.selectedAudience.majors = this.selectedAudience.majors.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeLocation(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.locations?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'city', name, this.selectedAudience.name);
      }
      this.selectedAudience.locations = this.selectedAudience.locations.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeSkill(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.skills?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'skill', name, this.selectedAudience.name);
      }
      this.selectedAudience.skills = this.selectedAudience.skills.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeInterest(name: string): Promise<void> {
    try {
      this.working = 25;
      if (this.selectedAudience?.interests?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'interest', name, this.selectedAudience.name);
      }
      this.selectedAudience.interests = this.selectedAudience.interests.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  async removeCertificate(name: string): Promise<void>{
    try {
      this.working = 25;
      if (this.selectedAudience?.certificates?.length) {
        await this.employerService.removeProfile(this.employer.urlName, 'certificate', name, this.selectedAudience.name);
      }
      this.selectedAudience.certificates = this.selectedAudience.certificates.filter(i => i !== name);
    } catch (error) {
      this.snackbar.error({
        message: `There was a problem removing ${name} from your company profile`,
      });
    } finally {
      this.working = 0;
    }
  }

  setupNewAudience(id: string): void {
    this.selectedAudience = {
      id: 0,
      interests: [],
      locations: [],
      majors: [],
      minors: [],
      name: '',
      schools: [],
      skills: [],
      certificates: []
    };
    this.createAudienceForm(this.selectedAudience);
    this.modals.open(id);
  }
  openAudience(id: string, aud: Audience): void {
    this.selectedAudience = aud;
    this.createAudienceForm(aud);
    this.modals.open(id);
  }

  // async deleteAudience(id: string): Promise<void> {
  //   this.selectedAudience = null;
  //   this.modals.close(id);
  // }
  closeAudience(id: string): void {
    this.selectedAudience = null;
    this.modals.close(id);
  }
  createAudienceForm(aud: Audience): void {
    this.audienceForm = this.fb.group({
      Id: new FormControl(aud.id),
      Name: new FormControl(aud.name),
      Interests: new FormControl(aud.interests),
      Locations: new FormControl(aud.locations),
      Majors: new FormControl(aud.majors),
      Minors: new FormControl(aud.minors),
      Schools: new FormControl(aud.schools),
      Skills: new FormControl(aud.skills),
      Description: new FormControl(aud.description),
      Details: new FormControl(aud.details),

      // opportunity types
      Shadowing: new FormControl(aud.jobShadow),
      Tour : new FormControl(aud.tour),
      Volunteering: new FormControl(aud.volunteering),
      Employment: new FormControl(aud.employment),
      CoOp: new FormControl(aud.coOp),
      Internship: new FormControl(aud.internship),
      
      // candiate Types
      UnderGraduate: new FormControl(aud.underGraduate),
      Graduate: new FormControl(aud.graduate),
      Doctorate: new FormControl(aud.doctorate),
      TechnicalDiploma: new FormControl(aud.technicalDiploma),
      HighSchool: new FormControl(aud.highSchool),
      PostGraduate: new FormControl(aud.postGraduate),
      JobCenters: new FormControl(aud.jobCenters),

      
    });
  }
  updatedSubscription(subscribed: boolean): void {
    this.employer.subscribed = subscribed;
    this.close('subscribe');
  }
  get audienceId(): number {
    return this.audienceForm.get('Id').value;
  }

  async saveAudience(): Promise<void> {
    try {
      this.working = 35;
      if (this.audienceId === 0) {
        const aud = await this.audienceService.updateAudience({
          id: 0,
          interests: this.selectedAudience.interests,
          locations: this.selectedAudience.locations,
          majors: this.selectedAudience.majors,
          minors: this.selectedAudience.minors,
          name: this.audienceForm.get('Name').value,
          schools: this.selectedAudience.schools,
          skills: this.selectedAudience.skills,

          jobShadow: this.audienceForm.get('Shadowing').value,
          tour: this.audienceForm.get('Tour').value,
          volunteering: this.audienceForm.get('Volunteering').value,
          employment: this.audienceForm.get('Employment').value,
          coOp: this.audienceForm.get('CoOp').value,
          internship: this.audienceForm.get('Internship').value,

          underGraduate: this.audienceForm.get('UnderGraduate').value,
          graduate: this.audienceForm.get('Graduate').value,
          doctorate: this.audienceForm.get('Doctorate').value,
          technicalDiploma: this.audienceForm.get('TechnicalDiploma').value,
          highSchool: this.audienceForm.get('HighSchool').value,
          postGraduate: this.audienceForm.get('PostGraduate').value,
          jobCenters: this.audienceForm.get('JobCenters').value,

          description: this.audienceForm.get('Description').value,
          details: this.audienceForm.get('Details').value


        });
        this.audiences.push(aud);
      } else {
        await this.audienceService.updateAudience({
          id: this.audienceForm.get('Id').value,
          interests: this.selectedAudience.interests,
          locations: this.selectedAudience.locations,
          majors: this.selectedAudience.majors,
          minors: this.selectedAudience.minors,
          name: this.audienceForm.get('Name').value,
          schools: this.selectedAudience.schools,
          skills: this.selectedAudience.skills,

          jobShadow: this.audienceForm.get('Shadowing').value,
          tour: this.audienceForm.get('Tour').value,
          volunteering: this.audienceForm.get('Volunteering').value,
          employment: this.audienceForm.get('Employment').value,
          coOp: this.audienceForm.get('CoOp').value,
          internship: this.audienceForm.get('Internship').value,

          underGraduate: this.audienceForm.get('UnderGraduate').value,
          graduate: this.audienceForm.get('Graduate').value,
          doctorate: this.audienceForm.get('Doctorate').value,
          technicalDiploma: this.audienceForm.get('TechnicalDiploma').value,
          highSchool: this.audienceForm.get('HighSchool').value,
          postGraduate: this.audienceForm.get('PostGraduate').value,
          jobCenters: this.audienceForm.get('JobCenters').value,

          description: this.audienceForm.get('Description').value,
          details: this.audienceForm.get('Details').value
        }, this.audienceForm.get('Id').value);
      }
    } catch (error) {
      this.snackbar.error({
        message: error.message,
        title: 'There was an error saving your audience'
      });
    } finally {
      this.working = 0;
    }
  }

  async saveYoutubeUrl() {
    try {
      this.working = 35
      const updateObj = Object.assign({}, this.employer) as any
      updateObj.youTubeUrl = this.youtubeFormGroup.get('YouTubeUrl').value?.length ? this.youtubeFormGroup.get('YouTubeUrl').value : null;
      await this.employerService.updateEmployer(updateObj);
    } catch (error) {
      this.snackbar.error({
        message: error.message,
        title: 'There was an error saving your YouTube URL'
      });
    } finally {
      this.working = 0
    }
  }

  async saveCompany(): Promise<void> {
    try {
      this.working = 35;
      const updateObj = Object.assign({}, this.employer) as any;
      updateObj.name = this.companyName().value;
      updateObj.domain = this.url().value;
      updateObj.emailDomains = this.emailDomain().value.replace(' ', '').split(',');
      updateObj.description = this.companyBio().value;
      updateObj.contactEmail = this.contactEmail().value;
      updateObj.companyBio = this.companyBio().value;
      updateObj.history = this.companyHistory().value;
      updateObj.values = this.companyValues().value;
      updateObj.shadowsAvailable = this.shadowsAvailable().value;
      updateObj.recruitmentPortal = this.recruitmentPortal().value;
      updateObj.sizeId = this.companySize().value.value;
      updateObj.externalCoopLink = this.externalCoopLink().value;
      updateObj.externalEmploymentLink = this.externalEmploymentLink().value;
      updateObj.externalInternshipLink = this.externalInternshipLink().value;
      updateObj.externalVolunteerLink = this.externalVolunteerLink().value;
      await this.employerService.updateEmployer(updateObj);
      this.snackbar.success({
        title: 'Success!',
        message: 'Your employer account has been updated.'
      });
    } catch (error) {
      this.snackbar.error({
        message: error.message,
        title: 'There was an error updating your company'
      });
    } finally {
      this.working = 0;
    }
  }

uploadType: string;
  employerImageUploaded(e: string[]): void {
    if(this.uploadType == 'logo'){
      this.employer.smallLogoUrl = e[0];
    }
    else if(this.uploadType == 'banner'){
      this.employer.logoUrl = e[0];
    }
    else if(this.uploadType == 'office preview'){
      this.employer.officePreview = e[0];
    }
    else if(this.uploadType == 'team preview'){
      this.employer.teamPreview = e[0];
    }
    else if(this.uploadType == 'misc preview'){
      this.employer.miscPreview = e[0];
    }

    
    this.modals.close('employerImageUpload');
  }



  selectField(e: any){
    this.selectedField = e.value;
  }
 selectedFieldLocation: any;
  selectFieldLocation(e: any){
    this.selectedFieldLocation = e.value
  }

  async addField(): Promise<void>{
    this.working = 50;
    try
    {
      let field : EmployerFieldOfInterest = {
       id: 0,
       employerId: this.employer.id,
       fieldOfInterestId: this.selectedField,
       employerLocationId: this.selectedFieldLocation,
      }
      field = await this.employerService.addEmployerFieldOfInterest(field, this.employer.urlName);
      field.name = this.allInterests.find(a => a.value == field.fieldOfInterestId).label
      this.employer.employerFieldOfInterests.push(field);
      this.setInterestLocations(this.employer.employerFieldOfInterests);
    } catch (error) {
      this.snackbar.error({
        message: error.message,
      });
    } finally {
      this.working = 0;
      this.fieldsControl.reset();
      this.selectedField = null;
      this.selectedFieldLocation = null;
    }
  }

  async removeField(e: any){
    this.employer.employerFieldOfInterests = await this.employerService.removeEmployerFieldOfInterest(e.id, this.employer.urlName);
    this.setInterestLocations(this.employer.employerFieldOfInterests);
  }
  onSearch(value: any, array: any): any {
    const orgSet = new Set(array);
    const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
    const combinedSet = new Set([...diffSet, ...orgSet ]);
    return Array.from(combinedSet);
  }

  createEmployeeForm(): void {
    this.employeeFormGroup = new FormGroup({
      FirstName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      LastName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Location: new FormControl('',  [Validators.required]),
      AllowAllLocations: new FormControl(false),
      Title: new FormControl('',[Validators.required])
    });
  }

  openEmployeeModal(){
    this.createEmployeeForm();
    this.modals.open('employeeInvite');
  }

  async saveEmployee(): Promise<void> {
    if (!this.employer) {
      this.snackbar.error({
        title: 'Setup Error',
        message: 'Something isn\'t set up right here, please refresh and try again.'
      });
      return;
    }
   
    const email = this.employeeFormGroup.get('Email').value;
    const domain: string[] = this.employer.emailDomains;
    if(domain.find(d => email.toLowerCase().endsWith(`@${d}`))) {
       this.employee = {
        id:0,
        firstName: this.employeeFormGroup.get('FirstName').value,
        lastName: this.employeeFormGroup.get('LastName').value,
        email: this.employeeFormGroup.get('Email').value,
        employerId: this.employer.id,
        userId: undefined,
        employerLocationId: this.employeeFormGroup.get('Location').value.value,
        allowAllLocations: this.employeeFormGroup.get('AllowAllLocations').value,
        title: this.employeeFormGroup.get('Title').value,
      }
      try {
        this.working = 50;
        this.employee = await this.employerService.createEmployee(this.employer.urlName, this.employee);
        this.snackbar.success({
          title: 'Success!',
          message: `${ this.employee.firstName } ${ this.employee.lastName } was added, going back to school...`
        });
      } catch (error) {
        this.snackbar.error({
          title: 'Error saving Staff',
          message: 'There was an error saving'
        });          
      } finally {
        this.working = 0;
      }
   } else {
      this.snackbar.error({
        title: 'Error saving Staff',
        message:'Email must match with the school domain.'
      });          
  }
    
  }

  oppSelection: any;
  oppSelections: any[] = [];
  setSelection(e: string){
    this.oppSelection = e;
  }

  addSelection(){
    const opp = this.oppSelections.find(a => a.label === this.oppSelection.label);
    if(opp == undefined || opp == null){
      this.oppSelections.push(this.oppSelection);

    }

   }

 

   toggleProp(prop: string) {
    const control = this.audienceForm.get(prop)
    control.setValue(!control.value);
  }

  getEmployerLocation(id: number){
    id = 45;
    return this.locations.find(a => a.value == id)?.label;
  }

  setInterestLocations(a: EmployerFieldOfInterest[]){
    a.forEach(a => {
      if(a.employerLocationId > 0){
        a.employerLocationName = this.locations.find(b => b.value == a.employerLocationId )?.label
      }
    });
    this.employer.employerFieldOfInterests = a;
  }



}
