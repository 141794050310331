<section id="hire-candidates" class="mb-medium standard-padding">
    <img class="hire" [src]="cdn.toCDN('images/for-employers/hire.png')"
        [srcset]="cdn.toCDN('images/for-employers/hire.png 2x') + ', ' + cdn.toCDN('images/for-employers/hire.png 3x')" />
    <div class="candidates">
        <div class="text text-shadow mb-small">
            <div class="text text-green">
                Increase recruiting efficiency &
            </div>
            <div class="text text-green-muted">
                build a pool of great talent
            </div>
        </div>
        <div class="text text-medium">
            <span class="text text-black">
                Build a pool of interested candidates that are familiar with your company, opportunities, and culture. 
                This helps build a strong brand on college campuses and increases the desirability of your company. 
            </span>
        </div>
    </div>
</section>

<section id="why-unext">
    <div id="why-unext-header" class="text">
        <div class="text text-center text-black">
            Why <span class="text text-green-muted">Unext</span>?
        </div>
    </div>
    <div id="why-unext-reasons" class="text">
        <div id="why-unext-mission">
            <h4 class="heading-4">Our Mission</h4>
            <p> 
                To help all students understand their major, build 
                confidence in their career, and graduate with a job that 
                they are familiar with and love. 
            </p>
        </div>
        <div id="why-unext-job-board">
            <h4 class="heading-4">Not a Traditional Job Board</h4>
            <p>
                On Unext, employers connect with more student 
                as soon as they enter college. Experience firsthand the 
                benefits of shadowing & volunteering opportunities.
            </p>
        </div>
    </div>
</section>

<section id="features" class="standard-padding">
    <div class="text text-green-muted text-medium text-center mb-medium ">
        Activities on Unext
    </div>
  
    <div class="card-container">
      <div class="card mb-small">
        <div class="text text-green-muted text-medium">
           Shadowing
        </div>
        <p class="text text-black text-normal">
            Build familiarity with candidates and build long term relationships for affective and supportive recruiting. 
        </p>
      </div>
  
      <div class="card mb-small">
        <div class="text text-green-muted text-medium">
          Volunteering
        </div>
        <p class="text text-black text-normal">
            Bring the community and students together.
        </p>
      </div>
  
      <div class="card mb-small">
        <div class="text text-green-muted text-medium">
           Internship & Employment
        </div>
        <p class="text text-black text-normal">
            Detail your reach and targeting for postings. 
            Students are looking for opportunities and can be reached from previous short-term opportunities (shadowing or volunteering) or a new posting. 
        </p>
      </div>
  
      <div class="card mb-medium">
        <div class="text text-green-muted text-medium">
          Other
        </div>
        <p class="text text-black text-normal">
            Be creative in your posting. Portray your events, courses, tours, and much more!
        </p>
      </div>
    </div>
    <img class="flair flair-1" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
    <img class="flair flair-2" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
</section>

<section id="questions" class="standard-padding mb-medium">
    <div class="rectangle-container">
        <div class="text heading-2 text-purple mb-medium">
            Have questions?
        </div>
        <div class="contact-faq">
            <div class="text text-normal text-black mb-medium">
                Check out our Frequently Asked Questions section to learn more about Unext, or send us a message using our contact us page.
            </div>
            <a [routerLink]="['/faqs']" class="button orange outline mb-small">
                Visit Faqs
            </a>
            <a [routerLink]="['/contact-us']" class="button btn-blue">
                Contact Us
            </a>
        </div>
        <img class="books" [src]="cdn.toCDN('images/for-students/bookshelf.png')" />
    </div>
</section>

<section id="connecting" class="standard-padding mb-medium">
    <div class="text heading-2 text-blue mb-small text-center">
        Connecting with students
    </div>
    <div class="text text-black text-center mb-medium">
        On Unext, you can post a wide range of information about your company and opportunities.
    </div>
    <div class="background gradient-green">
        <ul class="text text-center text-upper text-green-muted">
            <li>Precise matching logic</li>
            <li>Field of study search</li>
            <li>Talent and interest filtering</li>
            <li>Detailed employer profiles</li>
            <li>Short-term positions</li>
            <li>Long-term positions</li>
            <li>Internships</li>
            <li>Job shadowing</li>
            <li>Remote work positions</li>
        </ul>
    </div>
</section>

<section id="candidates-in-your-area" class="standard-padding-left">
    <app-candidates class="mb-medium"></app-candidates>
</section>

<section id="get-started" class="standard-padding mb-medium">
    <div class="text heading-2 text-green-accent text-center mb-medium">
        Ready to get started?
    </div>
    <div class="flair-left">
        <img class="flair flair-1" [src]="cdn.toCDN('images/abstracts/purple-abstract-shape.png')" />
        <img class="flair flair-2" [src]="cdn.toCDN('images/abstracts/purple-abstract-shape.png')" />
          </div>
    <div class="started-content">
        <div class="text text-black text-400 text-center mb-medium">
            No matter its size, through Unext, your company can notify student users of opportunities your company offers and inform them of the benefits of working for your company.
        </div>
        <div class="text text-medium text-black text-center mb-medium">
            Sign up and start posting opportunities today!
        </div>
        <div class="pricing-hiring">
            <a [routerLink]="['/pricing']" class="button btn-purple mb-small mr-small">
                View Pricing
            </a>
            <a class="button orange outline" [routerLink]="['/dashboard']">
                Start hiring
            </a>
        </div>
    </div>
    <div class="flair-right">
        <img class="flair flair-1" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
        <img class="flair flair-2" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
    </div>
</section>

<app-separator class="mb-small"></app-separator>

<app-signup class="mb-medium"></app-signup>
