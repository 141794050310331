<div class="list-view">
    <div class="bg-teal pl-medium pr-medium">
        <div class="bg-lime pl-medium pr-medium py-large">
            <app-filter-menu [vertical]="true" (outputListEvent)="updateStudentList($event)" [inputList]="students"
                [filterDropDownOptions]="studentFilterDropDownOptions" [searchOptions]="studentSearchOptions">
            </app-filter-menu>
        </div>
    </div>

    <div *ngIf="modifiedStudentList" class="bg-cream pl-medium pr-medium py-large">
        <div class="flex-columns mb-medium">
            <div class="text text-title mb-small mr-small">
                Manage Students
            </div>
            <div class="text text-900">
                {{modifiedStudentList.length}} results
            </div>
        </div>
        <div class="flex-columns justify-between grid-quarters">
            <ng-template ngFor let-s
                [ngForOf]="modifiedStudentList | sort: 'lastName':'asc' | paginate: {id: 'student-pager', itemsPerPage: 12, currentPage: studentPageNumber } ">
                <div class="mb-small profile-card bg-white pl-small pr-small py-small">
                    <div class="text text-title">
                        {{ s.firstName }} {{ s.lastName }}
                    </div>
                    <div class="flex-columns">
                        <div class="flex-rows">
                            <button *ngIf="showSwitch" class="btn lime-green" (click)="switchUserType(s)">
                                Set to Staff
                            </button>
                        </div>
                        <div class="avatar ml-auto">
                            <img [src]="s?.user.avatar" alt="profile pic" />
                        </div>
                    </div>
                    <button class="profile btn btn-white mr-small border-teal"
                        [routerLink]="['/students', this.s.urlName]">View Profile</button>
                </div>
            </ng-template>
        </div>
        <div class="mt-small flex-columns justify-around">
            <pagination-controls id="student-pager" (pageChange)="studentPageNumber = $event"></pagination-controls>
        </div>
    </div>
</div>