<div *ngIf="!isAdded" class="form mb-small">
    <div class="form-label bold text-center text heading-3 mb-small">
        {{ headerText }}
    </div>
    <div class="form-label text mb-small">
        {{ selectText }}
    </div>
    <input *ngIf="!useSelect" class="form-input form-input-rounded form-input-100 form-input-teal text"
            [placeholder]="placeholder"
            [ngModel]="search" (keyup)="updateSearch($event)" />
    <ng-select  *ngIf="useSelect" class="drop-down drop-down-button-blue" [items]="values | sort:'':'asc'"
        [closeOnSelect]="true" (search)="onSearch($event, values)" (change)="setSelection($event)" [placeholder]="placeholder" >
    </ng-select>
    <div class="value-wrapper">
        <div class="value" *ngIf="filtered && filtered.length && !isSelected()">
            <div class="text"
                    (click)="select(foi)"
                    *ngFor="let foi of filtered">
                {{ foi }}
            </div>
        </div>
    </div>
    <button class="button btn-{{ buttonColor }} mt-small" (click)="updateProfile(search)"
            [disabled]="search?.length && !isSelected()">
        {{ buttonText }}
    </button>
</div>
<app-progress-bar *ngIf="working" [value]="40"></app-progress-bar>
<div *ngIf="isAdded">
    Your preference has been added.
</div>