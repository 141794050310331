<div class="container nav-header" [ngClass]="{ 'active': user }">
    <app-logo class="mr-4" [loggedIn]="user"></app-logo>
    <nav class="ml-large mr-medium">
        <a [routerLink]="['/for-students']" *ngIf="!user" routerLinkActive="active" class="mx-auto">Students</a>
        <a [routerLink]="['/for-employers']" *ngIf="!user" routerLinkActive="active" class="mx-auto">Employers</a>
        <a [routerLink]="['/for-institutions']" *ngIf="!user" routerLinkActive="active" class="mx-auto">Institutions</a>
        <a [routerLink]="['/about-us']" *ngIf="!user" routerLinkActive="active" class="mx-auto">About</a>
        <a [routerLink]="['/contact-us']" *ngIf="!user" routerLinkActive="active" class="mx-auto">Contact</a>
        <a [routerLink]="['/dashboard']" *ngIf="!!user" routerLinkActive="active" class="mx-auto">Dashboard</a>
        <!-- <a [routerLink]="['/employers', user?.employee?.employerUrlName, 'opportunity']" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Opportunities</a> -->
        <a [routerLink]="['employers']" *ngIf="user?.isStudent" routerLinkActive="active" class="mx-auto">
            Opportunities
        </a>
        <!-- <a [routerLink]="['/my-favorites']" *ngIf="user?.isStudent" routerLinkActive="active" class="mx-auto">Favorites</a>-->
        <a [routerLink]="['/my-prospects']" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Prospects</a>
        <a [routerLink]="['/Analytics']" *ngIf="user?.isEmployer" routerLinkActive="active" class="mx-auto">Analytics</a>
        <a class="button white mx-auto" [routerLink]="['/request-demo']">Request Demo</a>
        <a *ngIf="!user" class="button lime-green mx-auto" (click)="login()">Login/Register</a>
        <a *ngIf="!!user" class="button mx-auto" (click)="logout()">Sign Out</a>
    </nav>
    <!--app-dropdown color="lime" [label]="defaultActionText" [persist]="false" (selected)="navSelection($event)" [actions]="availableNavActions"></app-dropdown-->
</div>