<section class="bg-lime">
  <div class="row">
    <div class="col">
      <h1>
        THE NEXT<span class="accent">step</span>
        IN CREATING
        CAREER
        <span class="accent">readiness</span>
      </h1>
      <div class="cta row">
        <a class="button dark-green outline" [routerLink]="['/dashboard']"
          (click)="handle('CTA','home','click','Get Started')">
          Get Started
        </a>
        <a [routerLink]="['/request-demo']" class="button dark-green outline">
          Request Demo
        </a>
      </div>
    </div>
    <div class="col">
      <img alt="A young woman with short, wavy brown hair" src="../../../assets/images/lady.webp" />
    </div>
  </div>
</section>