import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    (window as any).hbspt.forms.create({
      region: 'na1',
      portalId: '8167321',
      formId: 'b0dcd03e-3a17-407b-869a-388b043eff8d',
      target: '#hubspotForm'
    });
  
  }

}
