import { Component, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from 'src/app/shared/models';
import { UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-no-organization',
  templateUrl: './no-organization.component.html',
  styleUrls: ['./no-organization.component.scss']
})
export class NoOrganizationComponent {

  @Input()
  set user(value: User | undefined) {
    this.myUser = value;
  }
  get user(): User | undefined {
    return this.myUser;
  }

  private myUser: User | undefined;

  constructor(
    private auth0: AuthService,
    public users: UserService,
  ) { }

  isActive(): boolean {
    if (this.user) {
      if (this.user.isStudent) {
        return true;
      } else if (this.user.isEmployer) {
        return this.user.employee.employer.active;
      }
    }
    return true;
  }

  isVerified(): boolean {
    if (this.user) {
      if (this.user.isStudent) {
        return true;
      } else if (this.user.isEmployer) {
        return this.user.employee.employer.verified;
      }
    }
    return true;
  }

  signout(): void {
    this.users.logout();
    this.auth0.logout({
      logoutParams: {
        returnTo: document.location.origin
      }
    });
  }
}
