import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, lastValueFrom } from 'rxjs';
import { User } from '../models/user.model';
import { AuthApiConfig, UserApiConfig } from '../api';
import { UserContact } from '../models';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public get authenticatedUser(): User {
        return this.getStorageUser();
    }

    private loggedinSubject: Subject<string> = new BehaviorSubject('');
    public loggedInObservable$: Observable<string> = this.loggedinSubject.asObservable();

    private loginFailedSubject: Subject<string> = new BehaviorSubject('');
    public loginFailedObservable$: Observable<string> = this.loginFailedSubject.asObservable();

    private profileLoadedSubject: Subject<User> = new BehaviorSubject(null);
    public profileLoadedObservable$: Observable<User> = this.profileLoadedSubject.asObservable();
    private gettingProfile = false;
    private gettingAuth = false;

    public isAuthenticated(): boolean {
        return this.authenticatedUser != null;
    }

    public get verified(): boolean {
        return localStorage.getItem('user:verified') === 'Y';
    }
    public set verified(value: boolean) {
        localStorage.setItem('user:verified', value ? 'Y' : 'N');
    }

    constructor(private http: HttpClient) { }

    public async checkstatus(): Promise<Observable<User>> {
        if (this.gettingAuth) {
            return;
        }

        try {
            this.gettingAuth = true;
            const u = await lastValueFrom(this.http.get<User>(AuthApiConfig.getFullUrl('')));
            if (u) {
                this.setStorageUser(u);
                this.gettingAuth = false;
                this.loggedinSubject.next(u.messaging);
                this.profileLoadedSubject.next(this.authenticatedUser);
            } else {
                this.gettingAuth = false;
                this.loginFailedSubject.next('Login failed...');
            }
        } catch (error) {
            this.gettingAuth = false;
            console.error('Get User Error:', error);
        }
    }

    public getUsers(): Observable<User[]> {
        return this.http.get<User[]>(UserApiConfig.getFullUrl(''));
    }

    public async resendVerification(email: string, provider: string, providerid: string): Promise<void> {
      
        let params: HttpParams = new HttpParams();
        params = params.set('email', email).set('provider', provider).set('providerId', providerid);

        return this.http.get<any>(AuthApiConfig.getFullUrl('resend'), { params }).toPromise();
      }
  

    public reloadProfile(): void {
        localStorage.removeItem('a0at');
        this.clearProfile();
        this.getProfile();
    }
    public clearProfile(): void {
        this.clearStorageUser();
    }
    public async getProfile() {
        if (this.authenticatedUser) {
            this.profileLoadedSubject.next(this.authenticatedUser);
        } else {
            if (!this.gettingProfile) {
                const u = this.getStorageUser();
                if (u) {
                    this.setStorageUser(u);
                } else {
                    this.gettingProfile = true;

                    try {
                        const us = await this.http.get<User>(UserApiConfig.getFullUrl('me')).toPromise();
                        this.setStorageUser(us);
                        this.profileLoadedSubject.next(us);
                        this.gettingProfile = false;
                    } catch (reason) {
                        this.gettingProfile = false;
                    }
                }
            }
        }
    }
    public getUserProfile(id: string): Promise<User> {
        return lastValueFrom(this.http.get<User>(UserApiConfig.getFullUrl(id)));
    }

    public async updateUserProfile(model: User | any): Promise<User> {
        const u = await lastValueFrom(this.http.post<User>(UserApiConfig.getFullUrl(`${model.id}`), model));
        this.setStorageUser(u);
        return this.authenticatedUser;
    }

    public logout(): void {
        this.clearStorageUser();
        localStorage.clear();
    }

    private clearStorageUser(): void {
        localStorage.removeItem('userInfo');
    }
    private setStorageUser(value: User): void {
        localStorage.setItem('userInfo', JSON.stringify(value));
    }
    private getStorageUser(): User {
        return JSON.parse(localStorage.getItem('userInfo')) as User;
    }

    public setSystemMessage(value: string) {
        localStorage.setItem('SYSTEM', value);
    }
    public getSystemMessage(): string {
        return localStorage.getItem('SYSTEM');
    }
    public clearSystemMessage(): void {
        localStorage.removeItem('SYSTEM');
    }

    public getContactTypes(): Promise<UserContact[]>{
        return lastValueFrom(this.http.get<UserContact[]>(UserApiConfig.getFullUrl('contact')));
    }

}
