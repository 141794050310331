import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { FilterOption, School, Staff, Student } from 'src/app/shared/models';
import { ArraySortPipe } from 'src/app/shared/pipes';
import { SchoolService, StudentService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrl: './student-list.component.scss'
})
export class StudentListComponent implements OnInit {

  user: User;
  school: School;
  urlName: string;
  working = 0;
  studentPageNumber: number = 1;

  allStudentList?: Student[];

  formGroup: FormGroup;

  studentFilterDropDownOptions: FilterOption[] = [];
  students: any[];
  modifiedStudentList?: Student[];
  majorsList: any[];
  staffList: Staff[];
  studentSearchOptions: any[] = [];
  showSwitch: boolean = false;
  constructor(
    public cdn: CDNService,
    private schools: SchoolService,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private snackbar: ToastService,
    userService: UserService,
    private router: Router,
    private studentService: StudentService,
  ) {
    this.user = userService.authenticatedUser;
    this.route.params.subscribe(params => {
      // Retrieve the ID from the route parameters
      this.urlName = params['urlName'];
    });

  }
  async ngOnInit(): Promise<void> {
    const state = history.state;
    this.showSwitch = state && state.fromSpecificPage;
    this.school = await this.schoolService.get(this.urlName);
    this.allStudentList = await this.schoolService.getStudentList(this.urlName);
    this.modifiedStudentList = this.allStudentList;
    this.majorsList = await this.studentService.getStudies({ majors: true });
    this.staffList = await this.schoolService.getFacultyList(this.urlName);

    this.createStaffFilterOptions();
    this.createStaffFilterObjects();
  }

  async switchUserType(e: Student) {
    this.working = 50;
    try {
      await this.schoolService.switchToStaff(e.id);
      this.working = 90;
      const i = this.allStudentList.indexOf(e);
      this.allStudentList.splice(i, 1);
      this.snackbar.success({
        title: 'Switched',
        message: `${e?.user?.firstName} was switched to staff`
      });
    } catch (error) {
      this.snackbar.error({
        title: 'Error flipping to staff',
        message: 'There was a problem flipping this user to staff'
      });
    } finally {
      this.working = 0;
    }
  }


  createStaffFilterOptions() {
    const locations = [...new Set(this.allStudentList
      .filter(a => a.schoolLocation)
      .map(e => e.schoolLocation))]
      .sort();

    const majors = [...new Set(this.allStudentList
      .flatMap(s => s.majors))]
      .sort();

    const departments = [...new Set(this.allStudentList
      .filter(a => a.departmentName)
      .map(e => e.departmentName))]
      .sort();

    const graduationYears = [...new Set(this.allStudentList.map(a => a.graduationYear))]
      .filter(year => year > 1999)
      .sort((a, b) => a - b);

    if (locations.length) {
      this.studentFilterDropDownOptions.push({ id: 2, label: 'Campus', active: false, name: 'campus', options: locations });
    }
    if (this.majorsList.length) {
      this.studentFilterDropDownOptions.push({ id: 3, label: 'Major', active: false, name: 'major', options: majors });
    }
    if (graduationYears.length) {
      this.studentFilterDropDownOptions.push({ id: 4, label: 'Graduation Year', active: false, name: 'graduationYear', options: graduationYears });
    }

    if (departments.length) {
      this.studentFilterDropDownOptions.push({ id: 5, label: 'Department', active: false, name: 'department', options: departments });
    }

    //const advisors = this.staffList.map(a => a.firstName + " " + a.lastName);
    // if(advisors.length){
    //   this.studentFilterDropDownOptions.push( { id: 6, label: 'Advisor', active: false, name: 'advisor', options: advisors });
    // }

    this.studentSearchOptions = [
      { id: 1, name: 'name' },
    ];

  }

  createStaffFilterObjects() {
    this.students = [];
    this.allStudentList.forEach(e => {
      const s = {
        id: e.id,
        name: e.firstName + " " + e.lastName,
        school: e.schoolName,
        major: e.majors,
        advisor: '',
        graduationYear: e.graduationYear,
        department: e.departmentName,
        location: e.schoolLocation ?? this.school.primaryAddress
      }

      this.students.push(s);
    });
  }

  updateStudentList(data: { list: any[], sort: { value: string, asc: boolean }, search: string, reset: boolean }) {
    this.modifiedStudentList = [];
    if (data.list.length) {
      this.modifiedStudentList = new ArraySortPipe().transform(
        this.allStudentList.filter(a => data.list.some(i => i.id === a.id)),
        data.sort.value,
        data.sort.asc
      );
    }

    if (data.search.length) {
      const searchTermLower = data.search.toLowerCase();

      this.modifiedStudentList = this.modifiedStudentList.filter(e => {

        const nameMatches = (e.firstName + " " + e.lastName) && (e.firstName + " " + e.lastName).toLowerCase().includes(searchTermLower);
        return nameMatches;
      });
    }
  }


}
