import { Component, OnInit } from '@angular/core';
import { OpportunityService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {

  partnerOpportunities = [];
  working = 0;

  constructor(
    private opportunities: OpportunityService,
    public cdn: CDNService,
    private snackbar: ToastService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.working = 50;
    try {
      // const partners = await this.opportunities.getPartnerOpportunities().toPromise();
      // this.partnerOpportunities = [];
      // partners.map(o => {
      //   this.partnerOpportunities.push({
      //     name: o.name,
      //     url: o.url,
      //     route: ['/employers', o.urlName ],
      //     recentJobs: o.recentJobs,
      //     internships: o.internships,
      //     shadows: o.shadows,
      //     volunteeers: o.volunteers,
      //     employment: o.employment
      //   });
      // });
    } catch (error) {
      this.snackbar.error({
        message: 'Could not load partner information'
      });
    } finally {
      this.working = 0;
    }
  }


}
