import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ModalService, UserService } from "../services";

@Injectable()
export class ProfileGuard  {
    
    constructor(
        private users: UserService,
        private router: Router,
        private modals: ModalService) {

    }

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const au = this.users.authenticatedUser;
        // TODO:  REENABLE
        // if (au && !this.users.verified) {
        //     this.router.navigate(['/verify']);
        //     return false;
        // }
        // else 
        if (au?.student?.privacy) {
            this.modals.open('student-profile-progress');
            this.router.navigate(['/my-profile']);
            return false;
        } else if (au?.employee && !au.employee.employer?.active) {
            this.modals.open('employer-verification-active');
            this.router.navigate(['/dashboard']);            
            return false;
        } else if (au?.employee && !au.employee.employer?.verified) {
            this.modals.open('employer-verification-progress');
            this.router.navigate(['/dashboard']);            
            return false;
        } else if (au && !au?.isEmployer && !au?.isStudent && !au?.isAdmin) {
            this.router.navigate(['/dashboard']);            
            return false;
        } else {
            return true;
        }
    }
    
}