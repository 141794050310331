import { Component } from '@angular/core';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-for-students',
  templateUrl: './for-students.component.html',
  styleUrls: ['./for-students.component.scss'],
})
export class ForStudentsComponent {
  constructor(
    public cdn: CDNService
  ) {

  }
}
