<div *ngIf="!isAdded" class="form mb-small">
    <div class="form-label text text-black text-label" *ngIf="headerText?.length">
        {{ headerText }}
    </div>
    <div class="form-label text mb-small" *ngIf="selectText?.length">
        {{ selectText }}
    </div>

    <ng-select [(ngModel)]="search" class="drop-down drop-down-button-blue" [items]="values | sort:'':'asc'" (change)="selectItem($event)"
        [placeholder]="placeholder" [closeOnSelect]="true" [virtualScroll]="true" [addTag]="allowNew ? addNew.bind(this) : undefined" [addTagText]="addText" >
    </ng-select>

    <button type="button" class="btn btn-{{ buttonColor }} mt-small" (click)="updateProfile()"
            [disabled]="!search?.length" [ngClass]="{'hidden': addOnSelect }" >
        {{ buttonText }}
    </button>
</div>
<app-progress-bar *ngIf="working" [value]="40"></app-progress-bar>
<div *ngIf="isAdded">
    Your preference has been added.
</div>