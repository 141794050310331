import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseSectionComponent } from '../base-section/base-section.component';
import { CustomQuillComponent } from 'src/app/shared/components/custom-quill/custom-quill.component';
import { UNextEducation } from 'src/app/shared/models';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService, ResumeService, SchoolService, SkillService, StudentService } from 'src/app/shared/services';
import { ToastService } from 'src/app/shared/services/toast.service';
import { SmartWordService } from 'src/app/shared/services/smartwords.service';
import { DegreeService } from 'src/app/shared/services/degree.service';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { ArraySortPipe } from 'src/app/shared/pipes';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrl: './education.component.scss'
})
export class EducationComponent extends BaseSectionComponent implements OnInit {
  @ViewChild(CustomQuillComponent) quillDescription!: CustomQuillComponent;

  degreeTypes: DropDownOptions[] = [];
  allMajors: string[] = [];
  allMinors: string[] = [];
  allSchools: string[] = [];
  schoolLocations: string[] = [];

  constructor(
    private students: StudentService,
    route: ActivatedRoute,
    fb: FormBuilder,
    modals: ModalService,
    resumes: ResumeService,
    snackbar: ToastService,
    degrees: DegreeService,
    skills: SkillService,
    private schools: SchoolService,
    router: Router,
    public smartwords: SmartWordService) {

    super(route, fb, modals, resumes, skills, snackbar, router);

    this.forcePresentTense = undefined;
    this.validateStartDate = true;
    this.validateEndDate = true;
    this.validateDescription = false;
    this.maxDescriptionLength = 4000;

    this.degreeTypes = degrees.getDegreeDropdownValues();
    this.setupForm();
  }

  async ngOnInit(): Promise<void> {
    this.allMajors = await this.students.getStudies({ majors: true });
    // (await this.students.getStudies({ majors: true })).map(value => {
    //   // this.allMajors.push({ value: value, label: value });
    //   this.allMajors
    // });
    this.allMajors = new ArraySortPipe().transform(this.allMajors, 'label', true);

    this.allMinors = await this.students.getStudies({ minors: true });
    this.allMinors = new ArraySortPipe().transform(this.allMinors, 'label', true);
    this.allMinors.splice(0, 0, 'None');

    this.allSchools = await this.schools.getSchoolNames();

  }

  override setupForm(): void {
    super.setupForm();
    this.sectionForm.addControl('schoolName', new FormControl(undefined, Validators.required));
    this.sectionForm.addControl('degree', new FormControl(undefined, Validators.required));
    this.sectionForm.addControl('location', new FormControl(undefined));
    this.sectionForm.addControl('major', new FormControl(undefined, [Validators.required]));
    this.sectionForm.addControl('minor', new FormControl());
    this.formSetup = true;
  }

  async save(): Promise<any> {

    if (!this.sectionForm.valid) {
      this.snackbar.warning({
        message: 'There is a problem with the information you are trying to submit, please your entry',
        title: 'Invalid information'
      });
    } else {
      if (this.sectionForm.get('description')) {
        this.sectionForm.get('description').setValue(this.quillDescription.textValue);
      }
  
      let returnData: any = {};
      if (this.sectionForm) {
        Object.keys(this.sectionForm.controls).forEach((key) => {
          returnData[key] = this.sectionForm.get(key).value;
        });
      } else {
        returnData = { ...this.section };
      }
      returnData.resumeSectionId = this.currentResumeSection?.resumeSectionId ?? 0;
      returnData = await this.saveSection(returnData);
      this.quillDescription.initialValue = returnData.description;

      this.snackbar.success({
        message: `${this.section?.name ?? 'section'} updated`
      });

    }
    this.sectionForm.updateValueAndValidity();
  }

  override updateForm(): void {
    super.updateForm();
    if (this.sectionForm && this.formSetup) {
      const x = this.currentResumeSection as UNextEducation;
      this.sectionForm.get('schoolName').setValue(x?.schoolName);
      this.sectionForm.get('degree').setValue(x?.degree);
      this.sectionForm.get('location').setValue(x?.location);
      this.sectionForm.get('major').setValue(x?.major);
      this.sectionForm.get('minor').setValue(x?.minor);

      if (this.quillDescription) {
        this.quillDescription.initialValue = '';  //forces reset
        this.quillDescription.initialValue = this.sectionForm.get('description').value;
      }

      setTimeout(async () => {
        const name = this.sectionForm.get('schoolName')?.value;
        if (name?.length) {
          this.schoolLocations = (await this.schools.get(name))?.locations ?? [];
        }
      }, 100);

    }
  }

  addMajor = (value: string) => {
    this.sectionForm?.get('major').setValue(value);
  }
  addMinor = (value: string) => {
    this.sectionForm?.get('minor').setValue(value);
  }
  addSchool = (value: string) => {
    this.sectionForm?.get('schoolName').setValue(value);
  }
  async changeSchool(value: string): Promise<void> {
    const s = await this.schools.get(value);
    this.schoolLocations = s?.locations ?? [];
    this.sectionForm.get('location').setValue(this.schoolLocations.length ? this.schoolLocations[0] : '');
  }

}
