import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StudentService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {

  candidates = [];
  working = 0;

  @Output()
  public signUp = new EventEmitter<boolean>();

  constructor(
    private students: StudentService,
    public cdn: CDNService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.working = 50;
    try {
      const students = await this.students.getCandidates().toPromise();
      this.candidates = [];
      students.forEach((c: any) => {
        if (c.zip) {
          c.queryParams = {
            zip: c.zip
          };
        } else if (c.school) {
          c.queryParams = {
            school: c.school
          };
        } else if (c.city) {
          c.queryParams = {
            city: c.city,
            state: c.state
          };
        } else if (c.state) {
          c.queryParams = {
            state: c.state
          };
        }
        c.queryParams =
          this.candidates.push({
            location: c.location,
            students: c.count,
            majors: c.majors,
            queryParams: c.queryParams
          });
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.working = 0;
    }
  }

  public signup(): void {
    this.signUp.emit(true);
  }
}
