<app-progress-bar [value]="working" [splashScreen]="true"></app-progress-bar>

<div class="analytics-wrapper" *ngIf="section">
    <form [formGroup]="sectionForm">
        <!-- Clubs and Organizations -->
        <div class="form edit-section">
            <div class="six-col">
                <label class="form-label text text-black text-label">
                    Description
                </label>
                <div style="height: 350px;" class="form-input-quill">
                    <app-custom-quill #myCustomQuill [initialValue]="currentResumeSection?.description ?? section?.default"
                        placeholder="Enter description here..." (changed)="descriptionChange($event)"
                        (filled)="markDescriptionPristine()"
                        [checkWeakWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.weakWords)"
                        [checkStrongWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.strongWords)"
                        [checkActionWords]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.actionWords)"
                        [checkMetrics]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.metrics)"
                        [checkTense]="smartwords.checkAnalytic(section.analyze, SmartWordAnalyzer.tense)"
                        [presentTense]="current" (wordListEmitter)="updateWordList($event)" [words]="words">
                    </app-custom-quill>
                </div>
            </div>
        </div>
        <div class="form edit-section mt-large">
            <div class="three-col">
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.required ?? false">
                    Description is required
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.minlength ?? false">
                    Description is not long enough ({{ description?.errors?.minlength?.actualLength }} of {{
                    description?.errors?.minlength?.requiredLength }})
                </label>
                <label class="form-error" *ngIf="sectionForm.touched && description?.errors?.maxlength ?? false">
                    Description is too long ({{ description?.errors?.maxlength?.actualLength }} of {{
                    description?.errors?.maxlength?.requiredLength }})
                </label>

            </div>
            <div class="three-col row">
                <div class="ml-auto">
                    <button type="submit" (click)="cancel()" [disabled]="!hasUnsavedChanges">
                        Cancel
                    </button>    
                </div>
                <div>
                    <button type="submit" class="button lime-green" (click)="save()" [disabled]="!sectionForm?.valid">
                        Save Section
                    </button>    
                </div>
            </div>
        </div>
    </form>

    <app-analytics [section]="section" [analytics]="analytics"></app-analytics>
</div>