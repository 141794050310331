import { InviteMessage } from "./discussionMessage.model";

export enum OpportunityType
{
    Employment = 1,
    JobShadowing = 2,
    Internship = 3,
    Other = 4,
    Volunteering = 5,
    CoOp = 6,
}

export class NewOpportunity {
    title: string;
    description: string;
    expectations: string;
    hours: number | null;
    pay: number | null;
    payType: number | null;
    opportunityTypeId: number;
    startDate: Date;
    publishStartDate: Date;
    publishEndDate: Date;
    applicationCap: number | null;
    employerId: number;
    allowRemote: boolean;
    opportunityLength: string;
    bannerUrl: string;
    thumbnailUrl: string;

    locations: string[];
    skills: string[];
    majors: string[];
    minors: string[];
    schools: string[];
    degrees: number[];
    interests: string[];
    active: boolean;
}

export class UpdateOpportunity {
    id: number;
    title: string;
    description: string;
    expectations: string;
    hours: number | null;
    pay: number | null;
    payType: number | null;
    opportunityTypeId: number;
    startDate: Date;
    publishStartDate: Date;
    publishEndDate: Date;
    applicationCap: number | null;
    employerId: number;
    allowRemote: boolean;
    opportunityLengthId: number;
    opportunityLength: string;
    
    applicationUrl: string
    requireResume: boolean
    requireCoverLetter: boolean
    requireVideoResume: boolean
    requireTranscript: boolean
    locations: string[];

    youtubeUrl: string
    active: boolean;
    majors: string[];
    minors: string[];
    schools: string[];
    degrees: number[];
    skills: string[];
}

export class Opportunity {
    id: number;
    title: string;
    description: string;
    expectations: string;
    hours: number | null;
    pay: number | null;
    payType: number | null;
    opportunityTypeId: number;
    opportunityType: string;
    startDate: Date;
    active: boolean | null;
    publishStartDate: Date;
    publishEndDate: Date;
    applicationCap: number | null;
    numberApplicants: number | null;
    employerId: number;
    employerUrlName: string;
    employerName: string;
    employerLogoUrl: string;
    allowRemote: boolean;
    urlName: string;
    opportunityLengthId: number;
    opportunityLength: string;
    applied: boolean;
    favorite: boolean;
    accepted: boolean;
    rejected: boolean;
    complete: boolean;
    score: number;
    bannerUrl: string;
    thumbnailUrl: string;

    industries: string[];
    skills: string[];
    views: number;

    locations: string[];
    majors: string[];
    minors: string[];
    schools: string[];
    degrees: number[];
    interests: any;
    
    applicationUrl: string
    requireResume: boolean
    requireCoverLetter: boolean
    requireVideoResume: boolean
    requireTranscript: boolean

    youtubeUrl: string

}


export class OpportunityRequest {
    id: number;
    studentId: number;
    employerId: number;
    opportunityTypeId: number;
    opportunityType?: string;
    employerIndustry?: string;
    employerIndustryId: number;
    employerLocationId: number;
    employerLocation?: string;
    applications?: OpportunityRequestApplication[] = [];
    application?: OpportunityRequestApplication;
}


export class OpportunityRequestApplication {
    id: number;
    studentId: number;
    opportunityRequestId: number;
    resumeURL?: string;
    coverLetterURL?: string;
    portfolioURL?: string;
    sundayAvailability?: string;
    mondayAvailability?: string;
    tuesdayAvailability?: string;
    wednesdayAvailability?: string;
    thursdayAvailability?: string;
    fridayAvailability?: string;
    saturdayAvailability?: string;
    invites?: InviteMessage[] = [];
    employerInterestId?: number;
    employerInterest?: string;
}
