import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Employer, EmployerFieldOfInterest } from '../models/employer.model';
import { EmployerApiConfig } from '../api';
import { Employee, EmployerAddress, IdName, OpportunityRequest, User } from '../models';
import { skipAuthHeaders } from '../auth/auth-interceptor';
import { DropDownOptions } from '../components/dropdown/dropdown.component';

@Injectable({
    providedIn: 'root'
})
export class EmployerService {

    constructor(private http: HttpClient) { }

    public getPartners(): Promise<Employer[]> {
        return lastValueFrom(this.http.get<Employer[]>(EmployerApiConfig.getFullUrl('partner'), { headers: skipAuthHeaders }));
    }

    public getEmployers(
        includeInactive: true | false = false, includeUnverified: true | false = false, 
        includePrivate: true | false = false, includeUnsubscribed: true | false = false): Promise<Employer[]> {
        
            let params: HttpParams = new HttpParams();
        if (includeInactive) {
          params = params.set('includeInactive', true);
        }
        if (includeUnverified) {
          params = params.set('includeUnverified', true);
        }
        if (includePrivate) {
          params = params.set('includePrivate', true);
        }
        if (includeUnsubscribed) {
          params = params.set('includeUnsubscribed', true);
        }
        return lastValueFrom(this.http.get<Employer[]>(EmployerApiConfig.getFullUrl(''), { params , headers: skipAuthHeaders}));
    }

    public getEmployer(urlName: string): Promise<Employer> {
        return lastValueFrom(this.http.get<Employer>(EmployerApiConfig.getFullUrl(urlName), { headers: skipAuthHeaders }));
    }
    public getEmployee(urlName: string, id: number): Promise<Employee> {
        return lastValueFrom(this.http.get<Employee>(EmployerApiConfig.getFullUrl(`${urlName}/employee/${id}`), { headers: skipAuthHeaders }));
    }

    public updateDomains(id: number, domain: string, portal: string, emailDomains: string[]): Promise<Employer> {
        return lastValueFrom(this.http.post<Employer>(
            EmployerApiConfig.getFullUrl(`${id}/domains`), {
            domain,
            recruitmentPortal: portal,
            emailDomains
        }));
    }

    public updateAttribute(urlName: string, name: string, value: string): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(
            EmployerApiConfig.getFullUrl(`${urlName}/attributes`), {
            name,
            value
        }));
    }

    public updateCultures(urlName: string, value: string[]): Promise<Employer> {
        return lastValueFrom(this.http.post<Employer>(
            EmployerApiConfig.getFullUrl(`${urlName}/cultures`),
            value
        ));
    }

    public makeShadowsAvailable(id: number): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(
            EmployerApiConfig.getFullUrl(`${id}/shadows?available=true`), {}));
    }
    public makeShadowsUnAvailable(id: number): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(
            EmployerApiConfig.getFullUrl(`${id}/shadows?available=false`), {}));
    }
    public getEmployees(urlName: string): Promise<User[]> {
        return lastValueFrom(this.http.get<User[]>(EmployerApiConfig.getFullUrl(`${urlName}/employee`)));
    }

    public getCultures(urlName: string): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(EmployerApiConfig.getFullUrl(`${urlName}/cultures`)));
    }

    public getLocations(urlName: string): Promise<EmployerAddress[]> {
        return lastValueFrom(this.http.get<EmployerAddress[]>(EmployerApiConfig.getFullUrl(`${urlName}/location`)));
    }
    public getAllLocations(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(EmployerApiConfig.getFullUrl('location')));
    }
    public getStates(): Promise<string[]> {
        return lastValueFrom(this.http.get<string[]>(EmployerApiConfig.getFullUrl('location/state')));
    }

    public removeLocation(urlName: string, id: number): Promise<any> {
        return lastValueFrom(this.http.delete(EmployerApiConfig.getFullUrl(`${urlName}/location/${id}`)));
    }

    public createLocation(urlName: string, value: EmployerAddress): Promise<EmployerAddress> {
        return lastValueFrom(this.http.post<EmployerAddress>(
            EmployerApiConfig.getFullUrl(`${urlName}/location`), value));
    }

    public updateLocation(urlName: string, id: number, value: EmployerAddress): Promise<EmployerAddress> {
        return lastValueFrom(this.http.post<EmployerAddress>(
            EmployerApiConfig.getFullUrl(`${urlName}/location/${id}`), value));
    }

    public getSubscription(urlName: string): Promise<any> {
        return lastValueFrom(this.http.get<any>(EmployerApiConfig.getFullUrl(`${urlName}/subscription`)));
    }
    public addProfile(urlName: string, type: string, val: string, audience?: string): Promise<boolean> {
        let  params: HttpParams = new HttpParams();
        if (audience) {
            params = params.set('audience', audience);
        }
        const url = audience ? `${urlName}/profile/${audience}/${encodeURIComponent(type)}` : `${urlName}/profile/${encodeURIComponent(type)}`;
        return lastValueFrom(this.http.put<boolean>(
            EmployerApiConfig.getFullUrl(url), {
            value: val
        }, {
            params
        }));
    }
    public removeProfile(urlName: string, type: string, val: string, audience?: string): Promise<any> {
        const url = audience ? `${urlName}/profile/${audience}/${encodeURIComponent(type)}/${val}` : `${urlName}/profile/${encodeURIComponent(type)}/${val}`;
        return lastValueFrom(this.http.delete(EmployerApiConfig.getFullUrl(url)));
    }

    public updateEmployer(value: { id } ): Promise<Employer> {
        return lastValueFrom(this.http.post<Employer>(EmployerApiConfig.getFullUrl(`${value.id}`), value));
    }

    public requestShadow(employer: Employer): Promise<any> {
        return lastValueFrom(this.http.post(EmployerApiConfig.getFullUrl(`${employer.urlName}/shadow/request`), {}));
    }

    public getCreateSubscriptionLink(planId: number, returnUrl: string | undefined): Promise<any> {
        let params = new HttpParams();
        if (returnUrl?.length) {
            params = params.append('returnUrl', returnUrl);
        }
        return lastValueFrom(this.http.get(EmployerApiConfig.getFullUrl(`create-subscription-link/${planId}`), { params }));
    }

    public getManageSubscriptionLink(returnUrl: string | undefined): Promise<any> {
        let params = new HttpParams();
        if (returnUrl?.length) {
            params = params.append('returnUrl', returnUrl);
        }
        return lastValueFrom(this.http.get(EmployerApiConfig.getFullUrl('manage-subscription-link'), { params }));
    }

    public addEmployerFieldOfInterest(field: any, urlName: string): Promise<EmployerFieldOfInterest>{
        return lastValueFrom(this.http.post<EmployerFieldOfInterest>(EmployerApiConfig.getFullUrl(`${urlName}/field`), field));
    }
    public removeEmployerFieldOfInterest(employerFieldId: number, urlName: string): Promise<any>{
        return lastValueFrom(this.http.delete(EmployerApiConfig.getFullUrl(`${urlName}/field/${employerFieldId}`)));
    }

    public getEmployerLocations(urlName: string): Promise<{id: number; name: string;}[]> {
        return lastValueFrom(this.http.get<{id: number; name: string;}[]>(EmployerApiConfig.getFullUrl(`${urlName}/locations`)));
    }

    public createEmployee(urlName: string, employee: Employee): Promise<Employee>{
        return lastValueFrom(this.http.post<Employee>(EmployerApiConfig.getFullUrl(`${urlName}/employee`), employee));
    }

    public getEmployerOpportunities(urlName: string): Promise<any> {
        return lastValueFrom(this.http.get<any>(EmployerApiConfig.getFullUrl(`${urlName}/opportunities`)));
    }

    getEmployerTypesDropDownValues(): DropDownOptions[] {
        return this.getEmployerTypes().map(d => { return { label: d.name, value: d.id }});
    } 
    getEmployerTypes(): IdName[] {
        return [{
             id: 1, 
             name: 'Public' 
            }, {
                id: 2,
                name: 'Private'
            }, {
                id: 3,
                name : 'Start-Up',
            }];
    }

    public saveOpportunityRequest(employerUrlName: string, request: OpportunityRequest): Promise<OpportunityRequest> {
        return lastValueFrom(this.http.post<OpportunityRequest>(EmployerApiConfig.getFullUrl(`${employerUrlName}/request`), request));
    }
}



