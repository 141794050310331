import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-bar',
  templateUrl: './toggle-bar.component.html',
  styleUrls: ['./toggle-bar.component.scss']
})
export class ToggleBarComponent {

  @Input()
  on: boolean;
  @Input()
  onText: string;
  @Input()
  offText: string;
  @Input()
  size: 'small' | 'medium' | 'large' | '' = '';
  @Input()
  disabled = false
  @Input()
  textNowrap = false;

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleButton(e: Event) {
    if (this.disabled) return;
    e.preventDefault();
    this.toggle.next(!this.on);
  }
}
