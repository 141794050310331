import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { delay, lastValueFrom, Observable } from 'rxjs';
import { OpportunityApiConfig } from '../api/opportunity.api.config';
import { NewOpportunity, Opportunity, UpdateOpportunity } from '../models/opportunity.model';
import { Student } from '../models/student.model';
import { StudentOpportunity, StudentOpportunityApplication } from '../models/studentOpportunity.model';
import { IdName, IdValue } from '../models';
import { of } from 'rxjs';
import { skipAuthHeaders } from '../auth/auth-interceptor';
import { DropDownOptions } from '../components/dropdown/dropdown.component';

@Injectable({
    providedIn: 'root'
})
export class OpportunityService {

    constructor(private http: HttpClient) { }

    public getEmployerOpportunitySummary(
        id: string,
        includeInactve: boolean,
        includeOutOfDateRange: boolean,
        size?: number) {
        let url = OpportunityApiConfig.getFullUrl(`employer/${id}/summary`);
        const params = [ `includeInactve=${includeInactve}`, `includeOutOfDateRange=${includeOutOfDateRange}`];
        if (size > 0) {
            params.push(`size=${size}`);
        }

        if (params.length) {
            url += `?${params[0]}`;
            for (let i = 1; i < params.length; ++i) {
                url += `&${params[i]}`;
            }
        }
        return this.http.get<Opportunity[]>(url);
    }


    public getEmployerOpportunities(
        id: string,
        includeInactve: boolean,
        includeOutOfDateRange: boolean,
        orderBy: string | null,
        size: number | null,
        search: string,
        industry: string,
        location: string,
        type: number): Promise<Opportunity[]> {
        let url = OpportunityApiConfig.getFullUrl(`employer/${id}`);
        const params = [ `includeInactve=${includeInactve}`, `includeOutOfDateRange=${includeOutOfDateRange}`];
        if (orderBy) {
            params.push(`&orderby=${orderBy}`);
        }
        if (size) {
            params.push(`&size=${size}`);
        }
        if (orderBy) {
            params.push(`&orderBy=${orderBy}`);
        }
        if (search?.length) {
            params.push(`search=${search}`);
        }
        if (industry && industry.length) {
            params.push(`industryFilter=${industry}`);
        }
        if (location && location.length) {
            params.push(`locationFilter=${location}`);
        }
        if (type) {
            params.push(`opportunityType=${type}`);
        }
        if (params.length) {
            url += `?${params[0]}`;
            for (let i = 1; i < params.length; ++i) {
                url += `&${params[i]}`;
            }
        }
        return lastValueFrom(this.http.get<Opportunity[]>(url));
    }

    public getPartnerOpportunities(): Observable<any[]> {
        return this.http.get<any[]>(OpportunityApiConfig.getFullUrl('partner'));
    }

    public getOpportunity(id: string): Observable<Opportunity> {
        return this.http.get<Opportunity>(OpportunityApiConfig.getFullUrl(id));
    }

    public getOpportunityForStudent(id: string, urlName: string): Observable<StudentOpportunity> {
        return this.http.get<StudentOpportunity>(OpportunityApiConfig.getFullUrl(`${id}/${urlName}`));
    }

    public getApplicants(id: string): Observable<Student[]> {
        return this.http.get<Student[]>(OpportunityApiConfig.getFullUrl(`${id}/applicants`));
    }

    public updateOpportunity(id: number, model: UpdateOpportunity): Observable<Opportunity> {
        return this.http.post<Opportunity>(
            OpportunityApiConfig.getFullUrl(id), model);
    }

    public createOpportunity(model: NewOpportunity): Observable<Opportunity> {
        return this.http.post<Opportunity>(
            OpportunityApiConfig.getFullUrl(''), model);
    }

    public purgeOpportunityLengths(typeId: number) {
        const opportunityLengthsKey = `lookup:opportunity-type-lengths-${typeId}`;
        localStorage.removeItem(opportunityLengthsKey);
    }
    public async getOpportunityLengths(typeId: number): Promise<IdValue[]> {
        const opportunityLengthsKey = `lookup:opportunity-type-lengths-${typeId}`;

        if (!localStorage.getItem(opportunityLengthsKey)) {
            localStorage.setItem(opportunityLengthsKey, 'Loading');
            try {
                localStorage.setItem(opportunityLengthsKey, JSON.stringify(await lastValueFrom(this.http.get<IdValue[]>(OpportunityApiConfig.getFullUrl(`${typeId}/length`), { headers: skipAuthHeaders }))));            
            } catch {
                localStorage.removeItem(opportunityLengthsKey);
            }
        } else if (localStorage.getItem(opportunityLengthsKey) === 'Loading') {
            await delay(1000);
        }
        return JSON.parse(localStorage.getItem(opportunityLengthsKey));
    }

    public apply(id: number, application: StudentOpportunityApplication): Promise<boolean> {
        return lastValueFrom(this.http.post<boolean>(OpportunityApiConfig.getFullUrl(`${id}/apply`), application));
    }

    public accept(id: number): Observable<boolean> {
        return this.http.get<boolean>(OpportunityApiConfig.getFullUrl(`${id}/accept`));
    }

    public reject(id: number): Observable<boolean> {
        return this.http.get<boolean>(OpportunityApiConfig.getFullUrl(`${id}/reject`));
    }

    public getEmploymentTypes(): Observable<IdValue[]> {
        const vals: IdValue[] = [{
            id: 2,
            value: 'Employment',
            created: new Date()
        }, {
            id: 1,
            value: 'Internship',
            created: new Date()
        }, {
            id: 3,
            value: 'Shadow',
            created: new Date()
        }, {
            id: 5,
            value: 'Volunteering',
            created: new Date()
        }, {
            id: 4,
            value: 'Other',
            created: new Date()
        }];
        return of(vals);
    }

  
    getOpportunityTypes(): IdName[] {
        return [{
             id: 1, 
             name: 'Employment' 
            }, {
                id: 2,
                name: 'Shadow'
            }, {
                id: 3,
                name : 'Internship',
            } , {
                id: 4,
                name: 'Generic'
            }, {
                id: 5,
                name: 'Volunteering'
            }, {
                id: 6,
                name: 'Co Op'
            }
        ];
    }

    getOpportunityTypesDropdownValues(): DropDownOptions[] {
        return this.getOpportunityTypes().map(d => { return { label: d.name, value: d.id }});
    } 

    public addProfile(urlName: string, type: string, val: string): Observable<boolean> {
        return this.http.put<boolean>(
            OpportunityApiConfig.getFullUrl(`${urlName}/profile/${encodeURIComponent(type)}`), {
            value: val
        });
    }
    public removeProfile(urlName: string, type: string, val: string): Observable<boolean> {
        return this.http.delete<boolean>(
            OpportunityApiConfig.getFullUrl(`${urlName}/profile/${encodeURIComponent(type)}/${encodeURIComponent(val)}`));
    }
    public tagOpportunity(urlName: string, studentUrlName: string): Promise<boolean> {
        return lastValueFrom(this.http.get<boolean>(OpportunityApiConfig.getFullUrl(`${urlName}/${studentUrlName}/tag`)));
    }

    public getRecentProspects(urlName: string): Observable<Opportunity[]> {
        return this.http.get<Opportunity[]>(OpportunityApiConfig.getFullUrl(`${urlName}/prospects/recent`));
    }
    public getOpportunityProspects(urlName: string, id: number): Observable<StudentOpportunity[]> {
        return this.http.get<StudentOpportunity[]>(OpportunityApiConfig.getFullUrl(`${urlName}/prospects/${id}`));
    }
    public getOpportunityProspectsSearch(opportunityId: number, school: string, search: string | undefined = undefined): Promise<StudentOpportunity[]> {
        let params = new HttpParams();
        if (opportunityId) {
            params = params.append('opportunityId', opportunityId);
        }
        if (school?.length) {
            params = params.append('school', school);
        }
        if (search?.length) {
            params = params.append('search', search);
        }
        return lastValueFrom(this.http.get<StudentOpportunity[]>(OpportunityApiConfig.getFullUrl(`prospects/search`), { params }));
    }
    public getSearchProspects(
        urlName: string, search: string,
        graduationYear: number, school: string, major: string,
        location: string): Observable<Opportunity[]> {
        let params = new HttpParams().set('search', search);
        if (graduationYear) {
            params = params.append('graduationYear', graduationYear);
        }
        if (school?.length) {
            params = params.append('school', school);
        }
        if (major?.length) {
            params = params.append('major', major);
        }
        if (location?.length) {
            params = params.append('location', location);
        }

        return this.http.get<Opportunity[]>(OpportunityApiConfig.getFullUrl(`${urlName}/prospects`), { params });
    }

    async SuggestJobDescription(jobTitle: string): Promise<IdValue> {
        return await lastValueFrom(this.http.get<IdValue>(OpportunityApiConfig.getFullUrl(`suggest/description?jobTitle=${jobTitle}`)));
    }
    async SuggestImage(id: number, jobTitle: string, size: 'small' | 'medium' | 'large' = 'small', type: 'banner' | 'thumb' = 'banner'): Promise<IdValue> {
        return lastValueFrom(this.http.post<IdValue>(OpportunityApiConfig.getFullUrl(`${id}/suggest/image/${type}?jobTitle=${jobTitle}&size=${size}`), {}));
        // .subscribe({
        //     next: (event) => {
        //         if (event.type === HttpEventType.UploadProgress) {
        //         // this.progress = Math.round(100 * event.loaded / event.total);
        //         }
        //         else if (event.type === HttpEventType.Response) {
        //         // this.onUploadFinished.emit(event.body);
        //         }
        //     }
        // });
    }
}
