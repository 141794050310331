<div class="standard-padding">
    <div class="flex-columns justify-between mb-small">
        <div class="text heading-4 mt-auto mb-auto">
            Edit Company
        </div>
        <div>

            <a [routerLink]="[ '/employers', employer?.urlName ]"
            class="button button-link button-slim btn-green ml-auto text">View Company Profile</a>

            <a [routerLink]="[ '/employers',  employer?.urlName, 'subscription' ]"
            class="button button-link button-slim btn-green ml-auto text">Manage Subscription</a>
        </div>
    </div>

    <div class="my-company mt-small">
        <div class="rounded-corners general mr-medium mb-small">
            <div class="text heading-4 mb-small">
                General Information
            </div>

            <form class="form" [ngClass]="{ 'mb-medium': working }" [formGroup]="formGroup" *ngIf="formGroup"
                (ngSubmit)="saveCompany()">
                <div>
                    <label for="CompanyName" class="form-label text text-black">
                        Company Name
                    </label>
                    <input name="CompanyName" type="text" formControlName="CompanyName" required
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                    <label class="form-error" *ngIf="companyName().errors?.required">
                        Company Name is required
                    </label>
                </div>
                <div>
                    <label for="URL" class="form-label text text-black">
                        URL
                    </label>
                    <input name="URL" type="text" formControlName="URL" type="url" required
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                    <label class="form-error" *ngIf="url().errors?.required">
                        Domain is required
                    </label>
                    <label class="form-error" *ngIf="url().errors?.pattern">
                        URL needs to be in the format of https://abc.xyz
                    </label>
                </div>
                <div>
                    <label for="EmailDomain" class="form-label text text-black">
                        Email Domain(s)
                    </label>
                    <input name="EmailDomain" type="text" formControlName="EmailDomain" readonly
                        placeholder="mydomain.com, email.mydomain.com, comma separated"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>
                <div>
                    <label for="ContactEmail" class="form-label text text-black">
                        Contact Email
                    </label>
                    <input name="ContactEmail" type="text" formControlName="ContactEmail" required
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                    <label class="form-error" *ngIf="contactEmail().errors?.required">
                        Contact email is required
                    </label>
                    <label class="form-error" *ngIf="contactEmail().errors?.email">
                        Contact email must be a valid email address
                    </label>
                </div>
                <div>
                    <label for="CompanyBio" class="form-label text text-black">
                        Company Biography <app-info-modal [audience]="'Employers'" [section]="'Edit Company'"
                            [item]="'Bio'"></app-info-modal>
                    </label>
                    <div class="form-input-quill readonly">
                        <quill-editor id="CompanyBio" formControlName="CompanyBio" required
                            placeholder="Enter biography here" [modules]="quillConfiguration"
                            [sanitize]="true"></quill-editor>
                    </div>
                    <label class="form-error" *ngIf="companyBio().touched && companyBio().errors?.required">
                        Company bio is required
                    </label>
                    <label class="form-error" *ngIf="companyBio().errors?.minlength">
                        Company bio must be at least 50 characters
                    </label>
                </div>
                <div>
                    <label for="CompanyHistory" class="form-label text text-black">
                        Company History
                    </label>
                    <div class="form-input-quill readonly">
                        <quill-editor id="CompanyHistory" formControlName="CompanyHistory"
                            placeholder="Enter history here" [modules]="quillConfiguration"
                            [sanitize]="true"></quill-editor>
                    </div>
                </div>
                <div>
                    <label for="CompanyValues" class="form-label text text-black">
                        Company Values
                    </label>
                    <div class="form-input-quill readonly">
                        <quill-editor id="CompanyValues" formControlName="CompanyValues"
                            placeholder="Enter values here" [modules]="quillConfiguration"
                            [sanitize]="true"></quill-editor>
                    </div>
                </div>
                <div>
                    <label for="RecruitmentPortal" class="form-label text text-black">
                        Recruitment Portal
                    </label>
                    <input name="RecruitmentPortal" type="text" formControlName="RecruitmentPortal"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>
                <div class="mb-small">
                    <label for="ShadowsAvailable" class="form-label text text-black">
                        Shadows Available?
                    </label>
                    <app-toggle-bar size="medium" [on]="shadowsAvailable().value"
                        (toggle)="shadowsAvailable().setValue(!shadowsAvailable().value)"></app-toggle-bar>
                </div>
                <div>
                    <label for="CompanySize" class="form-label text text-black">
                        Company Size
                    </label>
                        <ng-select name="CompanySize" formControlName="CompanySize" 
                        class="drop-down drop-down-button-blue" [items]="allSizes | sort:'':true">
                        </ng-select>
                  
                </div>

                <div>
                    <label for="CompanySize" class="form-label text text-black">
                        Employer Type
                    </label>
                        <ng-select name="CompanySize" formControlName="EmployerType" 
                        class="drop-down drop-down-button-blue" [items]="employerTypes | sort:'':true">
                        </ng-select>
                    
                </div>

                <div>
                    <label for="ExternalCoopLink" class="form-label text text-black">
                        External Co-Op Link
                    </label>
                    <input name="ExternalCoopLink" type="text" formControlName="ExternalCoopLink"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>

                <div>
                    <label for="ExternalEmploymentLink" class="form-label text text-black">
                        External Employment Link
                    </label>
                    <input name="ExternalEmploymentLink" type="text" formControlName="ExternalEmploymentLink"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>

                <div>
                    <label for="ExternalInternshipLink" class="form-label text text-black">
                        External Internship Link
                    </label>
                    <input name="ExternalInternshipLink" type="text" formControlName="ExternalInternshipLink"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>

                <div>
                    <label for="ExternalVolunteerLink" class="form-label text text-black">
                        External Volunteer Link
                    </label>
                    <input name="ExternalVolunteerLink" type="text" formControlName="ExternalVolunteerLink"
                        class="form-input form-input-rounded form-input-100 form-input-green text" />
                </div>
                <div class="upload-image banner mb-small" (click)="openModal('employerImageUpload', 'banner')">
                    <app-avatar-image
                        [image]="employer?.logoUrl?.length > 0 ? employer?.logoUrl : cdn.toCDN('images/icons/camera.png')"
                        size="wide" fit="contain">
                    </app-avatar-image>
                    <button type="button"
                        class="button button-link btn-green plain text mt-auto mb-auto mr-auto"
                        (click)="openModal('employerImageUpload', 'banner')">
                        Upload & Change Company Banner<app-info-modal [audience]="'Employers'"
                            [section]="'Edit Company'" [item]="'Banner'"></app-info-modal>
                    </button>
                </div>

                <div class="upload-image mb-small" (click)="openModal('employerImageUpload', 'logo')">
                    <app-avatar-image
                        [image]="employer?.smallLogoUrl?.length > 0 ? employer?.smallLogoUrl : cdn.toCDN('images/icons/camera.png')"
                        size="md" [fit]="employer?.smallLogoUrl?.length > 0 ? 'cover' : 'contain'  ">
                    </app-avatar-image>
                    <button type="button"
                        class="button button-link btn-green plain text mt-auto mb-auto mr-auto"
                        (click)="openModal('employerImageUpload', 'logo')">
                        Upload & Change Company Logo <app-info-modal [audience]="'Employers'" [section]="'Edit Company'"
                            [item]="'Logo'"></app-info-modal>
                    </button>
                </div>
                <div class="upload-image mb-small" (click)="openModal('employerImageUpload', 'office preview')">
                    <app-avatar-image
                        [image]="employer?.officePreview?.length > 0 ? employer?.officePreview : cdn.toCDN('images/icons/camera.png')"
                        size="md" [fit]="employer?.officePreview?.length > 0 ? 'cover' : 'contain'  ">
                    </app-avatar-image>
                    <button type="button"
                        class="button button-link btn-green plain text mt-auto mb-auto mr-auto"
                        (click)="openModal('employerImageUpload', 'office preview')">
                        Upload & Change Office Preview <app-info-modal [audience]="'Employers'" [section]="'Edit Company'"
                            [item]="'Logo'"></app-info-modal>
                    </button>
                </div>
                <div class="upload-image mb-small" (click)="openModal('employerImageUpload', 'team preview')">
                    <app-avatar-image
                        [image]="employer?.teamPreview?.length > 0 ? employer?.teamPreview : cdn.toCDN('images/icons/camera.png')"
                        size="md" [fit]="employer?.teamPreview?.length > 0 ? 'cover' : 'contain'  ">
                    </app-avatar-image>
                    <button type="button"
                        class="button button-link btn-green plain text mt-auto mb-auto mr-auto"
                        (click)="openModal('employerImageUpload', 'team preview')">
                        Upload & Change Team Preview <app-info-modal [audience]="'Employers'" [section]="'Edit Company'"
                            [item]="'Logo'"></app-info-modal>
                    </button>
                </div>
                <div class="upload-image mb-small" (click)="openModal('employerImageUpload', 'misc preview')">
                    <app-avatar-image
                        [image]="employer?.miscPreview?.length > 0 ? employer?.miscPreview : cdn.toCDN('images/icons/camera.png')"
                        size="md" [fit]="employer?.miscPreview?.length > 0 ? 'cover' : 'contain'  ">
                    </app-avatar-image>
                    <button type="button"
                        class="button button-link btn-green plain text mt-auto mb-auto mr-auto"
                        (click)="openModal('employerImageUpload', 'misc preview')">
                        Upload & Change Misc Preview <app-info-modal [audience]="'Employers'" [section]="'Edit Company'"
                            [item]="'Logo'"></app-info-modal>
                    </button>
                </div>
                <button [disabled]="!formGroup.valid" type="submit" class="button dark-green">Save Changes</button>
            </form>
            <app-progress-bar *ngIf="working" [value]="working"></app-progress-bar>
        </div>
       

        <div class="rounded-corners mr-medium locations mb-small">
            <div class="text heading-4 mb-small">
                Locations
            </div>
            <div class="add-location mb-medium">
                <app-employer-add-profile-attribute [employer]="employer" attribute="city" buttonColor="orange"
                    placeholder="Add Location" buttonText="Add this Location" (added)="selectLocation($event)"
                    [values]="allLocations">

                </app-employer-add-profile-attribute>
                <div class="form">
                    <div class="text heading-5 mb-small">
                        Can't find your city? Add it here.
                    </div>
                    <div class="actions">
                        <input class="form-input form-input-rounded form-input-100 form-input-green mr-small mb-small"
                            type="text" placeholder="City..." [(ngModel)]="newCity" />
                        <app-dropdown color="green" #ddLocation label="State..." [persist]="true" [fill]="true"
                            (selected)="selectState($event)" [actions]="allStates"></app-dropdown>
                    </div>
                    <button type="button" class="button orange outline" (click)="addCity()"
                        [disabled]="!newCity?.length || !selectedState">Add Your Location</button>
                </div>
            </div>

            <div class="addresses">
                <ng-template ngFor let-a [ngForOf]="employer?.addresses">
                    <div class="mr-small mb-small">
                        <div class="chip chip-green chip-remove text"
                            (click)="removeCity(a)">
                            {{ a }}
                        </div>
                    </div>
                </ng-template>
            </div>

        </div>

        <div class="rounded-corners detail mr-medium mb-small additional-detail">
            <div class="text heading-4 mb-small">
                Manage Industries
            </div>

            <app-employer-add-profile-attribute [employer]="employer" attribute="industry" buttonColor="green"
                placeholder="Add Industries" buttonText="Add Industry" (added)="selectIndustry($event)"
                [values]="allIndustries">

            </app-employer-add-profile-attribute>

            <div class="industries">
                <ng-template ngFor let-i [ngForOf]="employer?.industries | sort:'name':true">
                    <div class="mr-small mb-small">
                        <div class="chip chip-green chip-remove chip-md text"
                            (click)="removeIndustry(i)">
                            {{ i }}
                        </div>
                    </div>
                </ng-template>
            </div>

        </div>

        <div *ngIf="employees" class="rounded-corners mr-small users ">
            <div class="flex-columns justify-between mb-medium">
                <div class="text heading-4 mb-small">
                    Manage Employees
                </div>
                <a (click)="openEmployeeModal()" class="button btn-no-wrap btn-green">New</a>
            </div>


            <ng-template  ngFor let-s [ngForOf]="employees ">
                <div class="rounded-corners mb-small">
                    <div class="text">
                        {{ s.firstName }} {{ s.lastName }}
                    </div>
                    <div class="text text-400">
                        {{ s.email }}
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="rounded-corners mr-medium mb-small audiences">
            <div class="text heading-4 mb-small">
                Audiences
            </div>

            <div class="rounded-corners mb-small audience" *ngFor="let a of audiences | sort:'name':true"
                (click)="openAudience('edit-audience', a)">
                <div class="text">
                    {{ a.name }}
                </div>
                <img class="view mr-small" [src]="cdn.toCDN('images/icons/open.png')" alt="view" />
            </div>
            <button *ngIf="!working" class="button dark-green" (click)="setupNewAudience('edit-audience')">Create
                Audience</button>
        </div>

        <div class="rounded-corners mr-medium mb-small youtube-form">
            <div class="text heading-4 mb-small">
                YouTube
            </div>

            <form class="form" [formGroup]="youtubeFormGroup" *ngIf="youtubeFormGroup && !working">
                <input name="YouTubeUrl" type="text" formControlName="YouTubeUrl"
                    placeholder="https://www.youtube.com/..."
                    class="form-input form-input-rounded form-input-100 form-input-green text" />

                <app-youtube-video [width]="320" [url]="youtubeFormGroup.get('YouTubeUrl').value"></app-youtube-video>
                <button type="button" class="button orange outline text mt-auto mb-auto"
                    [disabled]="working !== 0" (click)="saveYoutubeUrl()">
                    Save
                </button>
            </form>
        </div>
        
        <div class="rounded-corners mr-medium mb-small" style="width: 50vw !important">
            <div class="text heading-4 mb-small">
                field of interest
            </div>
            <div class="flex-columns justify-between">
                <div>
                    <ng-select [items]="allInterests" (change)="selectField($event)"  class="drop-down drop-down-button-blue" 
                        placeholder="Search for Field" [closeOnSelect]="true" (search)="onSearch($event, allInterests)" [formControl]="fieldsControl">
                    </ng-select>
                </div>  
                <div>
                    <ng-select (change)="selectFieldLocation($event)"  class="drop-down drop-down-button-blue" [items]="employerLocations"
                        placeholder="Search for Location" [closeOnSelect]="true" (search)="onSearch($event, locations)" [formControl]="fieldsControl">
                    </ng-select>
            
                </div>
                
                    <button class="button btn-add" (click)="addField()"></button>
            </div>
            <div class="industries">
                <ng-template ngFor let-i [ngForOf]="employer?.employerFieldOfInterests | sort:'name':true" >
                    <div *ngIf="i.name" class="mr-small mb-small">
                        <div class="chip chip-green chip-remove chip-md text"
                            (click)="removeField(i)">
                            <div>
                                <div>
                                    {{ i.name }}
                                </div>
                                <div *ngIf="i.employerLocationName">
                                    {{i.employerLocationName}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
            
        </div>

        <div class="rounded-corners mr-medium mb-small general">
            <div class="text heading-4">
                Review Opportunities
            </div>
            <ng-template ngFor let-i [ngForOf]="opportunities">
                <div>
                    <div class="flex-columns">
                        <div class="text heading-5 mt-small mr-small">
                            {{i.typeName}} 
                        </div>
                        <input type="checkbox" [checked]="i.active > 0" onclick="return false"/>

                    </div>
                    <div class="flex-columns justify-between">
                        <span>Total: {{i.opportunities.length}}</span>
                        <span>Draft: {{i.draft}}</span>
                        <span>Active: {{i.active}}</span>
                        <span>Expired: {{i.expired}}</span>
                    </div>
                    <div>
                        <span *ngIf="i.opportunityTypeId === opportunityTypes.Employment && employer.externalEmploymentLink">
                            Link: <a href="{{employer.externalEmploymentLink}}">{{employer.externalEmploymentLink}}</a>
                        </span>
                        
                        <span *ngIf="i.opportunityTypeId === opportunityTypes.Internship && employer.externalInternshipLink">
                            Link: <a href="{{employer.externalInternshipLink}}">{{employer.externalInternshipLink}}</a>
                        </span>
                        
                        <span *ngIf="i.opportunityTypeId === opportunityTypes.Volunteering && employer.externalVolunteerLink">
                            Link: <a href="{{employer.externalVolunteerLink}}">{{employer.externalVolunteerLink}}</a>
                        </span>
                        
                        <span *ngIf="i.opportunityTypeId === opportunityTypes.CoOp && employer.externalCoopLink">
                            Link: <a href="{{employer.externalCoopLink}}">{{employer.externalCoopLink}}</a>
                        </span>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="clear"></div>
    </div>
</div>

<app-contact-us></app-contact-us>

<app-modal id="edit-audience">
    <form class="form edit-audience" [formGroup]="audienceForm" *ngIf="audienceForm">
        <div class="col-2">
            <div class="mb-small col-3">
                <label for="name" class="form-label">
                    Name
                </label>
                <input name="name" type="text" formControlName="Name"
                    class="form-input form-input-rounded form-input-100 form-input-green" />
            </div>
        </div>

       

        <div class="col-2">
            <div class="mb-small col-3">
                <label for="Description" class="form-label text text-black">
                    Description
                </label>
                <div class="form-input-quill readonly">
                    <quill-editor id="Description" formControlName="Description"
                        placeholder="Enter description here" [modules]="quillConfiguration"
                        [sanitize]="true"></quill-editor>
                </div>
            </div>
        </div>

        <div class="col-2">
            <div class=" col-3">
                <label for="CompanyHistory" class="form-label text text-black">
                    Details
                </label>
                <div class="form-input-quill readonly">
                    <quill-editor id="Details" formControlName="Details"
                        placeholder="Enter additional details here. EX: Any documentation the student needs?" [modules]="quillConfiguration"
                        [sanitize]="true"></quill-editor>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute useSelect="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allAddresses" [attribute]="'city'" placeholder="Select an location" buttonColor="green"
                buttonText="Locations" (added)="selectedAudience.locations.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.locations?.length">
                <label class="form-label">
                    Add Location
                </label>
                <div class="chip-list">
                    <div *ngFor="let l of selectedAudience?.locations" (click)="removeLocation(l)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ l }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute useSelect="true" [audience]="selectedAudience" [employer]="employer" [values]="allSkills"
                [attribute]="'skill'" placeholder="Select a skill" buttonColor="green" buttonText="Add Skill"
                (added)="selectedAudience.skills.push($event)" [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.skills?.length">
                <label class="form-label">
                    Skills
                </label>
                <div class="chip-list">
                    <div *ngFor="let s of selectedAudience?.skills" (click)="removeSkill(s)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ s }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute [useSelect]="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allStudies" [attribute]="'major'" placeholder="Select a major" buttonColor="green"
                buttonText="Add major" (added)="selectedAudience.majors.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.majors?.length">
                <label class="form-label">
                    Majors
                </label>
                <div class="chip-list">
                    <div *ngFor="let m of selectedAudience?.majors" (click)="removeMajor(m)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ m }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute [useSelect]="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allStudies" [attribute]="'minor'" placeholder="Select a minor" buttonColor="green"
                buttonText="Add minor" (added)="selectedAudience.minors.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.minors?.length">
                <label class="form-label">
                    Minors
                </label>
                <div class="chip-list">
                    <div *ngFor="let m of selectedAudience?.minors" (click)="removeMinor(m)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ m }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute useSelect="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allSchools" [attribute]="'school'" placeholder="Select a school" buttonColor="green"
                buttonText="Add School" (added)="selectedAudience.schools.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.schools?.length">
                <label class="form-label">
                    Schools
                </label>
                <div class="chip-list">
                    <div *ngFor="let s of selectedAudience?.schools" (click)="removeSchool(s)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ s }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute [useSelect]="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allInterests" [attribute]="'interest'" placeholder="Select an interest" buttonColor="green"
                buttonText="Add Interest" (added)="selectedAudience.interests.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.interests?.length">
                <label class="form-label">
                    Interests
                </label>
                <div class="chip-list">
                    <div *ngFor="let i of selectedAudience?.interests" (click)="removeInterest(i)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ i.label }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <app-employer-add-profile-attribute useSelect="true" [audience]="selectedAudience" [employer]="employer"
                [values]="allCertifications" [attribute]="'certificate'" placeholder="Select a certificate" buttonColor="green"
                buttonText="Add certificate" (added)="selectedAudience.certificates.push($event)"
                [autoSave]="false"></app-employer-add-profile-attribute>
            <div class="mb-small col-3" *ngIf="selectedAudience?.certificates?.length">
                <label class="form-label">
                    certificates
                </label>
                <div class="chip-list">
                    <div *ngFor="let i of selectedAudience?.certificates" (click)="removeCertificate(i)"
                        class="chip chip-green chip-remove mb-small mr-small">
                        {{ i.label }}
                    </div>
                </div>
            </div>
        </div>
        <div></div>


        <div class="preferred-opportunity checkbox-section gray mb-small">
            <div class="text checkbox-section-title">
                Opportunity Type
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Shadowing"
                    class="form-input" />
                <div (click)="toggleProp('Shadowing')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Shadowing').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Shadowing" class="form-label" (click)="toggleProp('Shadowing')">
                    Shadowing
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Volunteering"
                    class="form-input" />
                <div (click)="toggleProp('Volunteering')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Volunteering').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Volunteering" class="form-label" (click)="toggleProp('Volunteering')">
                    Volunteering
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Internship"
                    class="form-input" />
                <div (click)="toggleProp('Internship')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Internship').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Internship" class="form-label" (click)="toggleProp('Internship')">
                    Internship
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Employment"
                    class="form-input" />
                <div (click)="toggleProp('Employment')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Employment').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Employment" class="form-label" (click)="toggleProp('Employment')">
                    Employment
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Tour"
                    class="form-input" />
                <div (click)="toggleProp('Tour')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Tour').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Tour" class="form-label" (click)="toggleProp('Tour')">
                    Tour
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="CoOp"
                    class="form-input" />
                <div (click)="toggleProp('CoOp')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('CoOp').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="CoOp" class="form-label" (click)="toggleProp('CoOp')">
                    Co-Op
                </label>
            </div>
        </div>

        <div class="preferred-opportunity checkbox-section gray mb-small">
            <div class="text checkbox-section-title">
                Candidate Type
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="UnderGraduate" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('UnderGraduate')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('UnderGraduate').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="UnderGraduate" class="form-label" (click)="toggleProp('UnderGraduate')">
                    Under Graduate
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Graduate" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('Graduate')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Graduate').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Graduate" class="form-label" (click)="toggleProp('Graduate')">
                    Graduate
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="Doctorate" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('Doctorate')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('Doctorate').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="Doctorate" class="form-label" (click)="toggleProp('Doctorate')">
                    Doctorate
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="TechnicalDiploma" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('TechnicalDiploma')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('TechnicalDiploma').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="TechnicalDiploma" class="form-label" (click)="toggleProp('TechnicalDiploma')">
                    Technical Diploma
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="HighSchool" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('HighSchool')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('HighSchool').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="HighSchool" class="form-label" (click)="toggleProp('HighSchool')">
                    High School
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="PostGraduate" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('PostGraduate')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('PostGraduate').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="PostGraduate" class="form-label" (click)="toggleProp('PostGraduate')">
                    Post Graduate
                </label>
            </div>
            <div class="flex-columns mb-xsmall">
                <input hidden type="checkbox" formControlName="JobCenters" [value]="true"
                    class="form-input" />
                <div (click)="toggleProp('JobCenters')" class="checkbox-display mr-small"
                    [ngClass]="{ 'checked': audienceForm.get('JobCenters').value }">
                    <div class="checkbox-fill"></div>
                </div>
                <label for="JobCenters" class="form-label" (click)="toggleProp('JobCenters')">
                    Job Centers
                </label>
            </div>
        </div>
        
        <app-progress-bar *ngIf="working" [value]="working"></app-progress-bar>

            <button type="button" class="button dark-green mb-small" (click)="saveAudience()">Save</button>
            <button class="button btn-blue mb-small" (click)="closeAudience('edit-audience')">Close</button>
    
    </form>
</app-modal>

<app-modal id="employerImageUpload">
    <app-upload acceptType="image" [entityType]="entityType.EmployerImage" [entityId]="employer?.id"
        (filesUploaded)="employerImageUploaded($event)" >>
    </app-upload>
</app-modal>

<app-modal id="employeeInvite" size="medium" heading="Invite employee">
    
        <div>
            <form *ngIf="employeeFormGroup" [formGroup]="employeeFormGroup" class="form mb-medium mt-medium" (submit)="saveEmployee()">
                <div class="stepper-content-split">
                    <div class="mb-large flex-rows">
                        
                        <div class="flex-rows">
                            <label for="FirstName" class="form-label text text-black">
                                First Name
                            </label>
                            <input 
                                required class="form-input form-input-rounded form-input-green text"
                                type="text" formControlName="FirstName" />
                        </div>
                        <div class="flex-rows">
                            <label for="lastName" class="form-label text text-black">
                                Last Name
                            </label>
                            <input 
                                required class="form-input form-input-rounded form-input-green text"
                                type="text" formControlName="LastName" />
                        </div>
                        <div class="flex-rows">
                            <label for="email" class="form-label text text-black">
                                Email
                            </label>
                            <input 
                                required class="form-input form-input-rounded form-input-green text"
                                type="text" formControlName="Email" />
                        </div>
                        <div class="flex-rows">
                            <label for="Title" class="form-label text text-black">
                                Title
                            </label>
                            <input required class="form-input form-input-rounded form-input-green text"
                                type="text" formControlName="Title" />
                        </div>
                        <div>
                            <label for="Location" class="form-label">
                                Location
                            </label>
                            <ng-select id="locationSelect" formControlName="Location" class="drop-down drop-down-button-blue" [items]="locations"
                                placeholder="Search for Location" [closeOnSelect]="true" (search)="onSearch($event, locations)">
                            </ng-select>
                        </div>

                        <div>
                            <label for="AllowAllLocations" class="form-label">
                                Allow All Locations
                            </label>
                            <input type="checkbox" formControlName="AllowAllLocations" class=" ml-small mr-auto mt-auto mb-auto"/>
                        </div>
                    </div>
                    <button type="submit" style="width:250px;" class="button dark-green text mr-auto mr-auto span">
                        Save
                    </button>
                </div>
            </form>
        </div>
    
</app-modal>