import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { Opportunity, StudentOpportunity, User } from 'src/app/shared/models';
import { OpportunityService, SchoolService, UserService } from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-employer-prospects',
  templateUrl: './employer-prospects.component.html',
  styleUrls: ['./employer-prospects.component.scss']
})
export class EmployerProspectsComponent implements OnInit {

  recents: Opportunity[] = [];
  allOpportunities: Opportunity[] = [];
  listOpportunities: DropDownOptions[] = [];
  selectedPropsects: StudentOpportunity[] = [];
  selectedOpportunity: Opportunity;
  user: User;
  working = 0;
  listSchools: DropDownOptions[] = []
  selectedSchool = ''
  search = ''

  constructor(
    private users: UserService,
    private opportunities: OpportunityService,
    public cdn: CDNService,
    private schoolService: SchoolService,
    private activatedRoute: ActivatedRoute,
    private snackbar: ToastService
  ) {
    this.user = this.users.authenticatedUser;
  }

  async ngOnInit(): Promise<void> {
    try {
      this.working = 50;
      await this.loadOpportunities();
      this.working = 75;
      await this.loadSchools();

      const params = this.activatedRoute.snapshot.queryParams;
      const schoolName = params.schoolName;
      if (schoolName) {
        this.selectedSchool = schoolName;
      }

      const opportunity = params.opportunity;
      if (opportunity) {
        this.selectedOpportunity = this.allOpportunities.find(x => x.id.toString() == opportunity);
      }

      this.working = 0;

      const search = params.search;
      if (search) {
        this.search = search;
      }

      await this.loadProspects();
    } catch (error) {
      this.snackbar.error({
        'message': 'There was an error loading prospects.'
      });
    } finally {
      this.working = 0;
    }
  }

  async loadSchools(): Promise<void> {
    const schools = await this.schoolService.getSchoolNames();
    this.listSchools = schools.map(x => {
      return {
        label: x,
        value: x
      }
    });
  }

  async loadOpportunities(): Promise<void> {
    this.allOpportunities = await this.opportunities.getEmployerOpportunities(
      this.user?.employee?.employerUrlName,
      true, true, 'name', null, null, null, null, null);
    this.allOpportunities.filter(o => o.active).forEach(o => {
      this.listOpportunities.push({
        label: `${new Date(o.publishStartDate) < new Date() && new Date(o.publishEndDate) > new Date() ? '(active) ' : ''}${o.title}`,
        value: o.id
      });
    });
  }

  async loadProspects() {
    if (this.working) return;
    try {
      this.working = 25;
      this.selectedPropsects = await this.opportunities.getOpportunityProspectsSearch(
        this.selectedOpportunity?.id,
        this.selectedSchool,
        this.search);
    } finally {
      this.working = 0;
    }
  }

  async selectOpportunity(value: DropDownOptions): Promise<void> {
    this.selectedOpportunity = this.allOpportunities.find(o => o.id === value.value);
    await this.loadProspects();
  }

  async selectSchool(value: DropDownOptions): Promise<void> {
    this.selectedSchool = value.value
    await this.loadProspects();
  }
}
