<div class="standard-padding">
    <a class="button button-link btn-orange" [routerLink]="[ '/administration', 'employer']">Back to List</a>
    <app-progress-bar [splashScreen]="true" [value]="working">
        <form [formGroup]="formGroup" class="form mb-large" *ngIf="employer" (ngSubmit)="save()">
            <div class="mr-small">
                <div class="flex-rows mb-medium">
                    <label for="Name" class="form-label">
                        Name
                    </label>
                    <input placeholder="Name" type="text" formControlName="Name"
                        class="form-input form-input-rounded form-input-teal text" />
                    <label class="form-error" *ngIf="name.errors?.required">
                        Name is required
                    </label>
                </div>
                <div class="flex-rows mb-medium">
                    <label for="Description" class="form-label">
                        Description
                    </label>
                    <quill-editor name="Description" formControlName="Description" [modules]="quillConfiguration"
                        [sanitize]="true"></quill-editor>
                        <label class="form-error" *ngIf="description.errors?.required">
                            Description is required
                        </label>
                    </div>
                <div class="flex-rows mb-medium">
                    <label for="Domain" class="form-label">
                        Domain
                    </label>
                    <input type="text" formControlName="Domain"
                        class="form-input form-input-rounded form-input-teal text" />
                        <label class="form-error" *ngIf="domain.errors?.required">
                            Domain is required
                        </label>
                    </div>
                <div class="flex-rows mb-medium">
                    <label for="Name" class="form-label">
                        Email Domains (use a | for multiple)
                    </label>
                    <input type="text" formControlName="EmailDomains"
                        class="form-input form-input-rounded form-input-teal text" />
                        <label class="form-error" *ngIf="emailDomains.errors?.required">
                            Email Domain(s) are required
                        </label>
                </div>
                <div class="flex-rows mb-medium">
                    <label for="ContactEmail" class="form-label">
                        Contact Email
                    </label>
                    <input placeholder="Email" type="email" formControlName="ContactEmail"
                        class="form-input form-input-rounded form-input-teal text" />
                        <label class="form-error" *ngIf="contactEmail.errors?.required">
                            Contact Email is required
                        </label>
                    </div>
                <div class="flex-rows mb-medium">
                    <label for="RecruitmentPortal" class="form-label">
                        Recruitment Portal
                    </label>
                    <input placeholder="Recruitment Portal" type="url" formControlName="RecruitmentPortal"
                        class="form-input form-input-rounded form-input-teal text" />
                        <label class="form-error" *ngIf="recruitmentPortal.errors?.required">
                            Recruitment Portal is required
                        </label>
                    </div>
                <div class="flex-rows mb-medium">
                    <label for="Size" class="form-label">
                        Size
                    </label>
                    <app-dropdown color="white" #ddSize label="Size" [persist]="true" [fill]="true"
                        [label]="selectedSize" (selected)="selectSize($event)" [actions]="sizes"></app-dropdown>
                    <label class="form-error" *ngIf="size.errors?.required">
                        Size is required
                    </label>
                    <!-- <input type="text" [value]="size.label" formControlName="Size"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <button type="submit" class="button dark-green" [disabled]="!formGroup.valid">Save</button>
            </div>
            <div>
                <!-- col 2 -->
                <!-- <app-upload [entityType]="entityType.EmployerImage"></app-upload> -->
                <div class="mb-medium logo-wrapper">
                    <div class="flex-rows logo" style="background-repeat: no-repeat;"
                        [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(logoUrl.value, true) + ')' }">
                        <label class="form-label">
                            Employer Logo
                        </label>
                    </div>
                </div>
                <div class="mb-medium logo-wrapper">
                    <div class="flex-rows logo" style="background-repeat: no-repeat;"
                        [ngStyle]="{ 'background-image': 'url(' + cdn.toCDN(smallLogoUrl.value, true) +')' }">
                        <label class="form-label">
                            Small Employer Logo
                        </label>
                    </div>
                </div>

                <div class="flex-rows mb-medium">
                    <label for="IsPrivate" class="form-label">
                        Private?  
                    </label>
                    <label class="form-label">
                        (Do not show to students)
                    </label>
                    <label class="form-label" *ngIf="isPrivate.value">
                        ("On" will not show to students)
                    </label>
                    <app-toggle-bar [on]="isPrivate.value" (toggle)="togglePrivate()" size="medium"></app-toggle-bar>
                    <!-- <input type="checkbox" formControlName="IsPrivate"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <div class="flex-rows mb-medium">
                    <label for="ShadowsAvailable" class="form-label">
                        Shadows Available
                    </label>
                    <app-toggle-bar [on]="shadowsAvailable.value" (toggle)="toggleShadow()" size="medium"></app-toggle-bar>
                    <!-- <input type="checkbox" formControlName="ShadowsAvailable"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <div class="flex-rows mb-medium">
                    <label for="Featured" class="form-label">
                        Featured Employer
                    </label>
                    <app-toggle-bar [on]="featured.value" (toggle)="toggleFeatured()" size="medium"></app-toggle-bar>
                    <!-- <input type="checkbox" formControlName="ShadowsAvailable"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <div class="flex-rows mb-medium">
                    <label for="Active" class="form-label">
                        Active (global on/off switch)
                    </label>
                    <app-toggle-bar [on]="active.value" (toggle)="toggleActive()" size="medium"></app-toggle-bar>
                    <!-- <input type="checkbox" formControlName="Active"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <div class="flex-rows mb-medium text" *ngIf="employer.verified">
                    ** This employer has been verified **
                </div>
                <div class="flex-rows mb-medium" *ngIf="!employer.verified">
                    <label for="Verified" class="form-label">
                        Verified (Unext sign-off to show to students)
                    </label>
                    <app-toggle-bar [on]="verified.value" (toggle)="toggleVerified()" size="medium"></app-toggle-bar>
                    <!-- <input type="checkbox" formControlName="Verified"
                        class="form-input form-input-rounded form-input-teal text" /> -->
                </div>
                <div class="flex-rows mb-medium text" *ngIf="employer.subscribed">
                    ** This employer is subscribed **
                </div>
                <div class="flex-rows mb-medium text text-500 text-red" *ngIf="!employer.subscribed">
                    ** This employer is not subscribed **
                </div>
            </div>
        </form>
        <div class="flex-rows" *ngIf="employees?.length">
            <h2 class="text ml-auto mr-auto mb-small" >Employees</h2>
            <app-admin-users [users]="employees"></app-admin-users>
        </div>

    </app-progress-bar>
</div>