import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DropDownOptions } from 'src/app/shared/components/dropdown/dropdown.component';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { ContactMethod, Employee, Employer, EntityType, Experience, IdValue, Resume, ResumeType, School, Staff, Student, User } from 'src/app/shared/models';
import { ArrayMatchSortPipe } from 'src/app/shared/pipes';
import {
  EmployerService, FieldOfInterestService, IndustryService, LocationService, ModalService,
  SchoolService,
  SizeService,
  SkillService, StudentService, StudyService, UserService
} from 'src/app/shared/services';
import { CDNService } from 'src/app/shared/services/cdn.service';
import { CultureService } from 'src/app/shared/services/culture.service';
import { DegreeService } from 'src/app/shared/services/degree.service';
import { PageModeService } from 'src/app/shared/services/pageMode.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { QuillConfiguration } from 'src/app/static/quill/quilll.config';
import { environment } from 'src/environments/environment';

type stepId = 'loading' | 'info' | 'employer info' | 'faculty info' | 'resume' | 'employer' | 'documentation' | 'preview' | 'bio' | 'bio skills' | 'availability';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrls: ['./user-profile-edit.component.scss']
})
export class UserProfileEditComponent implements OnInit {

  @ViewChild('stepper') stepper: StepperComponent

  public isEdit = false;
  public formGroup: FormGroup;
  public contactFormGroup: FormGroup;

  @Input()
  public user: User;

  public student: Student;
  public employee: Employee;
  public school: School;
  public employer: Employer;
  public staff: Staff;
  public entityTypes = EntityType;
  public resumeTypes = ResumeType;

  public cdnRoot = environment.cdn.root;
  quillConfiguration = QuillConfiguration;

  allSkills: string[];
  availableSkills: DropDownOptions[];
  selectedSkills: string[];
  allStudies: string[];
  availableMajors: DropDownOptions[];
  availableMinors: DropDownOptions[];
  selectedMajors: string[];
  selectedMinors: string[];
  allCultures: string[];
  availableInterests: DropDownOptions[];
  allFieldsOfInterest: string[];
  selectedInterests: string[];
  allIndustries: string[] = [];
  allCities: string[] = [];
  minGraduationYear: number;
  maxGraduationYear: number;
  availableYears: number[] = [];
  availableLocations: string[] = [];
  selectedExperience: Experience;
  degreeTypes: DropDownOptions[] = [];
  selectedDegree: number;
  selectedDepartment: number;
  contactMethods: DropDownOptions[] = [
    { label: 'None', value: ContactMethod.None },
    { label: 'Email', value: ContactMethod.Email },
    { label: 'Phone', value: ContactMethod.Phone },
    { label: 'Unext Message', value: ContactMethod.Message },
  ];

  loadProgress = 0;
  isMe = false;

  departments: DropDownOptions[] = [];
  department: string;
  positions: DropDownOptions[] = [];
  contactTypes: any[] = [];
  contactTypeList: DropDownOptions[] = [];
  dynamicControlNames: string[] = [];

  dows = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  activeStep = 0;
  steps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'loading',
    title: 'Loading',
    split: false,
    valid: () => false,
    next: () => { }
  }];
  studentSteps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'info',
    title: 'Basic Info',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.saveAndNext()
      // this.stepper.next()
    }
  }, {
    id: 'bio skills',
    title: 'About You',
    split: false,
    valid: () => {
      return true
    },
    next: () => {
      this.saveAndNext();
    }
  }, {
    id: 'availability',
    title: 'Availability',
    split: false,
    valid: () => {
      return true
    },
    next: () => {
      // this.stepper.next()
      this.saveAndNext();
    }
  }, {
    id: 'employer',
    title: 'Employer Preferences',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      // this.stepper.next()
      this.saveAndNext();
    }
  }, {
    id: 'documentation',
    title: 'Additional Information',
    split: false,
    valid: () => {
      return true
    },
    next: () => {
      this.stepper.next()
      // this.saveAndNext();
    }
  }, {
    id: 'preview',
    title: 'Preview',
    split: false,
    valid: () => {
      return true
    },
    next: async () => {
      const saveSuccessful = await this.onSave()
      if (saveSuccessful) {
        this.stepper.next();
        this.checkProfileProgress();

      }
    }
  }];
  staffSteps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'faculty info',
    title: 'General Info',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.saveAndNext();
    }
  }, {
    id: 'bio',
    title: 'About You',
    split: false,
    valid: () => {
      return true
    },
    next: async () => {
      const saveSuccessful = await this.onSave()
      if (saveSuccessful) {
        this.stepper.next();
        this.checkProfileProgress();
      }
    }

  }];


  employeeSteps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'employer info',
    title: 'Basic Info',
    split: true,
    valid: () => {
      return true
    },
    next: async () => {
      const saveSuccessful = await this.onSave()
      if (saveSuccessful) {
        this.stepper.next();
        this.checkProfileProgress();
      }
    }
  }];

  defaultSteps: { id: stepId, title: string, split: boolean, valid: () => boolean, next: () => void }[] = [{
    id: 'info',
    title: 'Basic Info',
    split: true,
    valid: () => {
      return true
    },
    next: () => {
      this.saveAndNext()
      // this.stepper.next()
    }
  }];

  resumeType: number;
  selectedResume: Resume;
  sizes: IdValue[];

  constructor(
    private users: UserService,
    private snackbar: ToastService,
    private skillService: SkillService,
    private studyService: StudyService,
    private cultureServuce: CultureService,
    private fieldOfInterestService: FieldOfInterestService,
    private industryService: IndustryService,
    private sizeService: SizeService,
    private studentService: StudentService,
    private locationService: LocationService,
    private modals: ModalService,
    private employerService: EmployerService,
    private pageMode: PageModeService,
    public cdn: CDNService,
    private students: StudentService,
    degrees: DegreeService,
    private router: Router,
    private schoolService: SchoolService,
  ) {

    this.degreeTypes = degrees.getDegreeDropdownValues();
    const now = new Date();
    this.minGraduationYear = now.getFullYear() - 10;
    this.maxGraduationYear = now.getFullYear() + 10;
    for (let i = this.minGraduationYear; i <= this.maxGraduationYear; ++i) {
      this.availableYears.push(i);
    }

    if (!this.user) {
      this.user = this.users.authenticatedUser;
    }
  }

  async ngOnInit(): Promise<void> {
    this.contactTypes = await this.users.getContactTypes();
    this.contactTypeList = this.contactTypes.map(x => { return { value: x.id, label: x.contactType } });
    this.sizes = await this.sizeService.get();
    await this.loadUser();
    this.createFormGroup(this.user);
    if (this.user.isStudent) {
      this.steps = this.studentSteps;
    } else if (this.user.isStaff) {
      this.steps = this.staffSteps;
    } else if (this.user.isEmployer) {
      this.steps = this.employeeSteps;
    } else {
      this.steps = this.defaultSteps;
    }
  }

  async loadUser(): Promise<void> {
    this.isMe = this.user?.id === this.users.authenticatedUser?.id;

    this.loadProgress = 10;
    this.allSkills = (await this.skillService.get()).sort();

    if (this.user?.isStudent) {

      try {
        this.loadProgress = 20;
        this.allStudies = (await this.studyService.get()).sort();
        this.loadProgress = 30;
        this.allCultures = (await this.cultureServuce.get()).sort();
        this.loadProgress = 40;
        this.allIndustries = (await this.industryService.get()).sort();
        this.loadProgress = 50;
        this.allCities = await this.locationService.getCities();
        this.loadProgress = 60;
        this.allFieldsOfInterest = (await this.fieldOfInterestService.get()).map(s => s.name).sort();
        this.loadProgress = 70;
        this.student = await this.studentService.getStudent(this.user.student.urlName);
        this.loadProgress = 80;
        this.school = await this.schoolService.get(this.student.schoolUrlName);
        this.departments = (await this.schoolService.getSchoolDepartments(this.school.id)).map(x => { return { value: x.id, label: x.departmentName } });
        if (this.student) {
          this.selectedDegree = this.student.degree;
          this.selectedDepartment = this.student.schoolDepartmentId;
          this.student.employerSizes = this.student.employerSizes || [];
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        this.loadProgress = 0;
      }
    } else if (this.user?.isEmployer) {
      try {
        this.loadProgress = 25;
        this.employer = await this.employerService.getEmployer(
          await this.user.employee.employerUrlName);
        this.availableLocations = this.employer.addresses;
      } catch (err) {
        console.log('Error getting addresses');
      } finally {
        this.loadProgress = 0;
      }
    } else if (this.user?.isStaff) {
      try {
      //this.school = await this.schoolService.getSchool(this.user.staff.schoolId);
      this.loadProgress = 25;
      this.school = await this.schoolService.get(this.user.staff.schoolUrlName);
      this.loadProgress = 35;
      this.staff = await this.schoolService.getStaffByUrlName(this.user.staff.schoolUrlName, this.user.staff.urlName);

      this.loadProgress = 45;
      this.departments = (await this.schoolService.getSchoolDepartments(this.school.id)).map(x => { return { value: x.id, label: x.departmentName } });
      this.department = this.departments.find(d => d.value == this.user?.staff?.schoolDepartmentId)?.label;
      this.positions = this.school?.positions.map(x => { return { value: x.id, label: x.name } });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadProgress = 0;
      }
    }

  }

  async onSave(): Promise<boolean> {
    if (!this.user) {
      return;
    }
    this.loadProgress = 20;

    this.formGroup.disable();
    const formData = this.contactFormGroup.value;

    // Extract the contact types from the dynamic controls
    const contactTypes = [];
    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        // Check if the value is empty
        if (formData[key]) {
          const contactType = {
            contactType: key, // Assuming key is the name of the dynamic control
            contactInfo: formData[key], // Assuming formData[key] contains the value entered in the dynamic control
            userId: this.user.id,
            contactTypeId: this.contactTypes.find(ct => ct.type === key).id, // Find the contact type ID by matching the type
            preferredContactMethod: this.formGroup.get('PreferredContactMethod').value.label == key ? true : false
          };
          contactTypes.push(contactType);
        }
      }
    }

    // Now you can use the contactTypes array as needed
    // For example, if you want to assign it to this.user.contactTypes
    this.user.contactTypes = contactTypes;


    if (this.user.isStudent) {
      try {
        this.user = await this.users.updateUserProfile({
          id: this.user.id,
          firstName: this.formGroup.get('FirstName').value,
          lastName: this.formGroup.get('LastName').value,
          phone: this.formGroup.get('Phone').value,
          email: this.user.email,
          graduationYear: this.formGroup.get('GraduationYear').value ?? (new Date()).getFullYear(),
          degree: this.formGroup.get('Degree').value,
          location: this.formGroup.get('Location').value === -1 ? null : this.formGroup.get('Location').value,
          biography: this.formGroup.get('Biography').value,
          shadowing: this.formGroup.get('Shadowing').value || false,
          volunteering: this.formGroup.get('Volunteering').value || false,
          internship: this.formGroup.get('Internship').value || false,
          employment: this.formGroup.get('Employment').value || false,
          other: this.formGroup.get('Other').value || false,
          connecting: this.formGroup.get('Connecting').value || false,
          authorizedUS: this.formGroup.get('AuthorizedUS').value || false,
          requireVisa: this.formGroup.get('RequireVisa').value || false,
          requireOPTCPT: this.formGroup.get('RequireOPTCPT').value || false,
          preferredContactMethod: this.formGroup.get('PreferredContactMethod').value.value,
          employerRecommendations: this.formGroup.get('EmployerRecommendations').value ?? false,
          fullTime: this.formGroup.get('FullTime').value || false,
          partTime: this.formGroup.get('PartTime').value || false,
          remote: this.formGroup.get('Remote').value || false,
          inPerson: this.formGroup.get('InPerson').value || false,
          locationNoPreference: this.formGroup.get('LocationNoPreference').value || false,
          employerSizes: (this.formGroup.get('EmployerSizes') as FormArray).controls.map(x => x.value),
          contactTypes: contactTypes,
          schoolDepartmentId: this.formGroup.get('SchoolDepartmentId').value,
          availability: {
            sunday: this.formGroup.get('Sunday').value,
            monday: this.formGroup.get('Monday').value,
            tuesday: this.formGroup.get('Tuesday').value,
            wednesday: this.formGroup.get('Wednesday').value,
            thursday: this.formGroup.get('Thursday').value,
            friday: this.formGroup.get('Friday').value,
            saturday: this.formGroup.get('Saturday').value,
          }
        });
        this.loadProgress = 0;
        this.isEdit = false;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return true;
      } catch (reason) {
        this.snackbar.error({ message: 'There was an error updating your student profile' });
        this.loadProgress = 0;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return false;
      }
    } else if (this.user.isEmployer) {
      try {
        this.user = await this.users.updateUserProfile({
          id: this.user.id,
          firstName: this.formGroup.get('FirstName')?.value,
          lastName: this.formGroup.get('LastName')?.value,
          phone: this.formGroup.get('Phone')?.value,
          title: this.formGroup.get('Title')?.value,
          location: this.formGroup.get('Location').value === -1 ? null : this.formGroup.get('Location').value,
          contactTypes: contactTypes
        });
        this.loadProgress = 0;
        this.isEdit = false;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return true;
      } catch (reason) {
        this.snackbar.error({ message: 'There was an error updating your profile' });
        this.loadProgress = 0;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return false;
      }
    } else if (this.user.isStaff) {
      try {
        const department = this.departments.find(d => d.label == this.formGroup.get('SchoolDepartmentId').value);
        const position = this.positions.find(p => p.label == this.formGroup.get('Position').value);

        this.user = await this.users.updateUserProfile({
          id: this.user.id,
          firstName: this.formGroup.get('FirstName')?.value,
          lastName: this.formGroup.get('LastName')?.value,
          phone: this.formGroup.get('Phone').value,
          positionId: this.formGroup.get('Position').value.value ?? position.value,
          schoolDepartmentId: this.formGroup.get('SchoolDepartmentId').value.value ?? department.value,
          preferredContactMethod: this.formGroup.get('PreferredContactMethod').value.value,
          contactTypes: contactTypes
        });

        this.loadProgress = 0;
        this.isEdit = false;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return true;
      } catch (reason) {
        this.snackbar.error({ message: 'There was an error updating your staff profile' });
        this.loadProgress = 0;
        this.formGroup.enable();
        this.pageMode.closeEditMode();
        return false;
      }

    }
  }

  beginEdit(event: Event): void {

    if (event && !this.isEdit) {
      this.isEdit = true;
      this.pageMode.openEditMode();
    }
  }
  async cancel() {
    this.isEdit = false;
    this.pageMode.closeEditMode();
    await this.loadUser();
    this.createFormGroup(this.user);
  }

  private createFormGroup(user: User | null): void {

    // Create a separate form group for contact types
    const contactFormControls = {};

    this.contactTypes?.forEach(contactType => {
      const existingContact = user.contactTypes?.find(ct => ct.contactType === contactType.type);
      const initialValue = existingContact ? existingContact.contactInfo : '';
      contactFormControls[contactType.type] = new FormControl(initialValue);
    });

    // Initialize the contactFormGroup
    this.contactFormGroup = new FormGroup(contactFormControls);

    const position = this.positions.find(p => p.value == user.staff?.positionId);
    const department = this.departments.find(d => d.value == (user.student?.schoolDepartmentId ?? user.staff?.schoolDepartmentId));
    const contactMethod = this.user?.contactTypes?.find(c => c.preferredContactMethod === true);

    //    if (user?.isStudent && this.student) {
    this.formGroup = new FormGroup({
      FirstName: new FormControl(user?.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      LastName: new FormControl(user?.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
      Phone: new FormControl(user?.phone, [Validators.pattern(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)]),
      Email: new FormControl(user?.email, [Validators.required, Validators.email]),
      GraduationYear: new FormControl(this.student?.graduationYear ?? (new Date).getFullYear()),
      Location: new FormControl(user?.location?.id ?? -1),
      SchoolName: new FormControl(this.student?.schoolName),
      Degree: new FormControl(this.student?.degree),
      Address1: new FormControl(user?.location?.address.address1),
      Address2: new FormControl(user?.location?.address.address2),
      City: new FormControl(user?.location?.address?.city?.name),
      State: new FormControl(user?.location?.address?.city?.state?.name),
      Zip: new FormControl(user?.location?.address?.zipCode),
      Biography: new FormControl(this.student?.biography),
      Shadowing: new FormControl(this.student?.shadowing),
      Volunteering: new FormControl(this.student?.volunteering),
      Internship: new FormControl(this.student?.internship),
      Employment: new FormControl(this.student?.employment),
      Other: new FormControl(this.student?.other),
      Connecting: new FormControl(this.student?.connecting),
      AuthorizedUS: new FormControl(this.student?.authorizedUS),
      RequireVisa: new FormControl(this.student?.requireVisa),
      RequireOPTCPT: new FormControl(this.student?.requireOPTCPT),
      EmployerRecommendations: new FormControl(this.student?.employerRecommendations),
      PreferredContactMethod: new FormControl(this.student?.preferredContactMethod ?? contactMethod?.contactType),
      FullTime: new FormControl(this.student?.fullTime),
      PartTime: new FormControl(this.student?.partTime),
      Remote: new FormControl(this.student?.remote),
      InPerson: new FormControl(this.student?.inPerson),
      LocationNoPreference: new FormControl(this.student?.locationNoPreference),
      EmployerSizes: new FormArray((this.student?.employerSizes || []).map(x => new FormControl(x))),
      SchoolDepartmentId: new FormControl(department?.label),
      Sunday: new FormControl(this.student?.availability.sunday),
      Monday: new FormControl(this.student?.availability.monday),
      Tuesday: new FormControl(this.student?.availability.tuesday),
      Wednesday: new FormControl(this.student?.availability.wednesday),
      Thursday: new FormControl(this.student?.availability.thursday),
      Friday: new FormControl(this.student?.availability.friday),
      Saturday: new FormControl(this.student?.availability.saturday),
      Title: new FormControl(user?.employee?.title, [Validators.min(5)]),
      Position: new FormControl(position?.label, [Validators.required])
    });

    this.selectedSkills = [];
    this.availableSkills = [];
    this.selectedMajors = [];
    this.availableMajors = [];
    this.selectedMinors = [];
    this.availableMinors = [];
    this.selectedInterests = [];
    this.availableInterests = [];

    if (this.allSkills) {
      this.allSkills.sort().forEach(s => {
        this.alignSkills(s, false);
      });
    }
    if (this.allStudies) {
      this.allStudies.sort().forEach(s => {
        this.alignMajors(s, false);
        this.alignMinors(s, false);
      });
    }
    if (this.allFieldsOfInterest) {
      this.allFieldsOfInterest.sort().forEach(i => {
        this.alignInterests(i, false);
      });
    }

    if (this.user && this.student) {

      if (this.allSkills) {
        this.student.skills.forEach(s => {
          this.alignSkills(s, true);
        });
      }
      if (this.allStudies) {
        this.student.majors.forEach(s => {
          if (!this.allStudies.find(x => x === s)) {
            this.allStudies.push(s);
          }
          this.alignMajors(s, true);
        });
        this.student.minors.forEach(s => {
          if (!this.allStudies.find(x => x === s)) {
            this.allStudies.push(s);
          }
          this.alignMinors(s, true);
        });
      }
      if (this.allFieldsOfInterest) {
        this.student.fieldsOfInterest.forEach(i => {
          this.alignInterests(i.name, true);
        })
      }
    }
    //    } else if (user?.isEmployer) {
    // this.formGroup = new FormGroup({
    //   FirstName: new FormControl(user?.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    //   LastName: new FormControl(user?.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    //   Email: new FormControl(user?.email, [Validators.required, Validators.email]),
    //   Title: new FormControl(user?.employee.title, [Validators.min(5)]),
    //   Degree: new FormControl(''),
    //   Location: new FormControl(user?.location?.id ?? -1),
    //   SchoolName: new FormControl(''),
    //   Address1: new FormControl(user?.location?.address.address1),
    //   Address2: new FormControl(user?.location?.address.address2),
    //   City: new FormControl(user?.location?.address?.city?.name),
    //   State: new FormControl(user?.location?.address?.city?.state?.name),
    //   Zip: new FormControl(user?.location?.address?.zipCode),
    //   Biography: new FormControl(''),
    // });

    //    } else if(user?.isStaff){
    // const position = this.positions.find(p => p.value == user.staff.positionId);
    // const department = this.departments.find(d => d.value == user.staff.schoolDepartmentId);
    // const contactMethod = this.user.contactTypes.find(c => c.preferredContactMethod === true);

    // const formControls = {
    //   FirstName: new FormControl(user?.firstName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    //   LastName: new FormControl(user?.lastName, [Validators.required, Validators.minLength(2), Validators.maxLength(100)]),
    //   Email: new FormControl(user?.email, [Validators.required, Validators.email]),
    //   SchoolName: new FormControl(''),
    //   Department: new FormControl(department?.label,  [Validators.required]),
    //   Position: new FormControl(position?.label,  [Validators.required]),
    //   PreferredContactMethod : new FormControl(contactMethod?.contactType ?? ''),
    // };


    // // Create the form group
    // this.formGroup = new FormGroup(formControls);
    //    }
  }

  async alignSkills(val: string, selected: boolean) {
    if (this.allSkills) {
      const selectedSkill = this.allSkills.find(s => s === val);

      if (selected) {
        this.selectedSkills.push(selectedSkill);

        for (let i = 0; i < this.availableSkills.length; ++i) {
          if (this.availableSkills[i].value === val) {
            this.availableSkills.splice(i, 1);
          }
        }
      } else {
        this.availableSkills.push({ label: selectedSkill, value: selectedSkill });
        for (let i = 0; i < this.selectedSkills.length; ++i) {
          if (this.selectedSkills[i] === val) {
            this.selectedSkills.splice(i, 1);
          }
        }
      }
    }
  }

  selectDegree(val: DropDownOptions): void {
    this.beginEdit(null);
    this.formGroup.get('Degree').setValue(val.value);
  }
  selectedMajor(val: string) {
    if (!this.allStudies.find(x => x === val)) {
      this.allStudies.push(val);
    }
    this.alignMajors(val, true);
  }
  async deselectMajor(val: string) {
    try {
      if (await this.studentService.removeProfile(this.student.urlName, 'major', val)) {
        this.snackbar.success({ message: `${val} was removed from your profile` });
        this.alignMajors(val, false);
      } else {
        console.error('did not remove major');
      }
    } catch (error) {
      console.error(error);
    }
  }
  selectedMinor(val: string) {
    if (!this.allStudies.find(x => x === val)) {
      this.allStudies.push(val);
    }
    this.alignMinors(val, true);
  }

  async deselectMinor(val: string) {
    try {
      if (await this.studentService.removeProfile(this.student.urlName, 'minor', val)) {
        this.snackbar.success({ message: `${val} was removed from your profile` });
        this.alignMinors(val, false);
      } else {
        console.error('did not remove minor');
      }
    } catch (error) {
      console.error(error);
    }
  }
  selectedSkill(val: string) {
    this.alignSkills(val, true);
  }

  async deselectSkill(val: string) {
    try {
      if (await this.studentService.removeProfile(this.student.urlName, 'skill', val)) {
        this.alignSkills(val, false);
      } else {
        console.error('did not remove skill');
      }
    } catch (error) {
      console.error(error);
    }
  }

  selectInterest(val: string) {
    this.alignInterests(val, true);
  }
  async deselectInterest(val: string) {
    try {
      if (await this.studentService.removeProfile(this.student.urlName, 'fieldofinterest', val)) {
        this.alignInterests(val, false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  alignMajors(val: string, selected: boolean) {
    if (this.allStudies) {
      this.alignStudies(val, selected, this.selectedMajors, this.availableMajors);
    }
  }

  alignMinors(val: string, selected: boolean) {
    if (this.allStudies) {
      this.alignStudies(val, selected, this.selectedMinors, this.availableMinors);
    }
  }

  alignInterests(val: string, selected: boolean) {
    if (this.allFieldsOfInterest) {
      const selectedInterest = this.allFieldsOfInterest.find(s => s === val);

      if (selected) {
        this.selectedInterests.push(selectedInterest);

        for (let i = 0; i < this.availableInterests.length; ++i) {
          if (this.availableInterests[i].value === val) {
            this.availableInterests.splice(i, 1);
          }
        }
      } else {
        this.availableInterests.push({ label: selectedInterest, value: selectedInterest });
        for (let i = 0; i < this.selectedInterests.length; ++i) {
          if (this.selectedInterests[i] === val) {
            this.selectedInterests.splice(i, 1);
          }
        }
      }
    }
  }

  alignStudies(val: string, selected: boolean, selections: string[], available: DropDownOptions[]) {
    const selectedStudy = this.allStudies.find(s => s === val);

    if (selected) {
      selections.push(selectedStudy);
      for (let i = 0; i < available.length; ++i) {
        if (available[i].value === val) {
          available.splice(i, 1);
        }
      }
    } else {
      available.push({ label: selectedStudy, value: selectedStudy });
      for (let i = 0; i < selections.length; ++i) {
        if (selections[i] === val) {
          selections.splice(i, 1);
        }
      }
    }
  }

  async deselectCity(val: string) {
    if (await this.studentService.removeProfile(this.student.urlName, 'city', val)) {
      this.snackbar.success({ message: `${val} was removed from your profile` });
      for (let i = 0; i < this.student.cities.length; ++i) {
        if (this.student.cities[i] === val) {
          this.student.cities.splice(i, 1);
          break;
        }
      }
    }
  }
  async deselectFieldOfInterest(val: string) {
    if (await this.studentService.removeProfile(this.student.urlName, 'fieldofinterest', val)) {
      this.snackbar.success({ message: `${val} was removed from your profile` });
      for (let i = 0; i < this.student.fieldsOfInterest.length; ++i) {
        if (this.student.fieldsOfInterest[i].name === val) {
          this.student.fieldsOfInterest.splice(i, 1);
          break;
        }
      }
    }
  }
  async deselectCulture(val: string) {
    if (await this.studentService.removeProfile(this.student.urlName, 'culture', val)) {
      this.snackbar.success({ message: `${val} was removed from your profile` });
      for (let i = 0; i < this.student.cultures.length; ++i) {
        if (this.student.cultures[i] === val) {
          this.student.cultures.splice(i, 1);
          break;
        }
      }
    }
  }
  async deselectIndustry(val: string) {
    if (await this.studentService.removeProfile(this.student.urlName, 'industry', val)) {
      this.snackbar.success({ message: `${val} was removed from your profile` });
      for (let i = 0; i < this.student.industries.length; ++i) {
        if (this.student.industries[i] === val) {
          this.student.industries.splice(i, 1);
          break;
        }
      }
    }
  }

  isEmployer(): boolean {
    return this.user && this.user.isEmployer;
  }

  isStudent(): boolean {
    return this.user && this.user.isStudent;
  }

  public openModal(id: string): void {
    this.modals.open(id);
  }
  closeModal(name: string) {
    this.modals.close(name);
  }

  addFieldOfInterest(id: string, value: string) {
    this.student.fieldsOfInterest.push({ id: parseInt(id, 10), name: value });
    this.student.fieldsOfInterest = this.student.fieldsOfInterest.sort();
    this.modals.close(id);
  }
  addCity(id: string, value: string) {
    this.student.cities.push(value);
    this.student.cities = this.student.cities.sort();
    this.modals.close(id);
  }
  addIndustry(id: string, value: string) {
    this.student.industries.push(value);
    this.student.industries = this.student.industries.sort();
    this.modals.close(id);
  }
  addCulture(id: string, value: string) {
    this.student.cultures.push(value);
    this.student.cultures = this.student.cultures.sort();
    this.modals.close(id);
  }
  addExperience(value: Experience, id: string) {
    let found = false;
    for (let i = 0; i < this.student.experiences.length; ++i) {
      if (this.student.experiences[i].id === value.id) {
        this.student.experiences[i] = value;
        found = true;
        break;
      }
    }
    if (!found) {
      this.student.experiences.push(value);
    }
    if (id) {
      this.closeModal(id);
    }
  }
  selectExperience(value: Experience, id: string) {
    this.selectedExperience = value;
    this.openModal(id);
  }

  addResume(id: string, type: EntityType): void {
    this.resumeType = type;
    this.openModal(id);
  }

  currentStepValid() {
    return this.steps[this.activeStep].valid();
  }

  async filesUploaded(success: boolean, id: string): Promise<void> {
    if (success) {
      this.modals.close(id);
      await this.loadUser();
    }
  }

  view(resume: Resume): void {
    window.open(this.cdn.toCDN(resume.url, true), '_new');
  }
  remove(resume: Resume): void {
    this.selectedResume = resume;
    this.modals.open('confirm-delete');
  }

  getResumes(resumes: Resume[], types: ResumeType[]) {
    return resumes.filter(x => types.indexOf(x.resumeType) > -1);
  }

  getResume(resumes: Resume[], type: ResumeType) {
    return this.getResumes(resumes, [type])[0]
  }

  anyResumes(resumes: Resume[], types: ResumeType[]) {
    return this.getResumes(resumes, types).length > 0;
  }

  async confirmDelete(id: string): Promise<void> {
    this.modals.close(id);
    if (await this.students.deleteResume(this.student.urlName, this.selectedResume.id).toPromise()) {
      for (let i = 0; i < this.student.resumes.length; ++i) {
        if (this.student.resumes[i].id === this.selectedResume.id) {
          this.student.resumes.splice(i, 1);
          break;
        }
      }
    }
  }
  cancelDelete(id: string): void {
    this.selectedResume = null;
    this.modals.close(id);
  }

  toggleProp(prop: string) {
    const control = this.formGroup.get(prop)
    control.setValue(!control.value);
  }

  noLocationPreference(prop: string) {
    this.toggleProp(prop);
    this.formGroup.controls.Remote.setValue(false);
    this.formGroup.controls.InPerson.setValue(false);
  }

  toggleSize(id: number) {
    const formArray: FormArray = this.formGroup.get('EmployerSizes') as FormArray;
    let found = false;
    for (let i = 0; i < formArray.controls.length; i++) {
      const ctrl = formArray.controls[i]
      if (ctrl.value === id) {
        formArray.removeAt(i);
        found = true;
        continue;
      }
    }
    if (!found) {
      formArray.push(new FormControl(id));
    }
  }

  sizeChecked(id: number) {
    const formArray: FormArray = this.formGroup.get('EmployerSizes') as FormArray;
    for (const ctrl of formArray.controls) {
      if (ctrl.value === id) {
        return true;
      }
    }
  }

  async continueLater() {
    const saveSuccessful = await this.onSave()
    if (saveSuccessful) {
      this.router.navigate(['/dashboard']);
    }
  }

  async saveAndNext() {
    const saveSuccessful = await this.onSave()
    if (saveSuccessful) {
      this.stepper.next()
    }
  }

  checkProfileProgress(): void {
    this.router.navigate(['/dashboard'])
  }

  resumeBuilderClicked() {
    this.router.navigate(['/resume-builder']);
  }

  // staff logic
  onSearch(value: any, array: any): any {
    // Create a set from this.allMajors to ensure uniqueness
    const orgSet = new Set(array);
    // Transform value.items and convert it to a set to ensure uniqueness
    const diffSet = new Set(new ArrayMatchSortPipe().transform(value.items, value.term));
    // Combine sets without duplicates
    const combinedSet = new Set([...diffSet, ...orgSet]);
    // Convert the set back to an array
    return Array.from(combinedSet);
  }
}
