<section id="for-students" class="mt-medium standard-padding">
  <div class="heading mb-medium">
      <img class="logo" [src]="cdn.toCDN('images/logos/unext-overlap.png')" alt="Unext" />
      <span class="sr-only">Unext</span>
      <span class="text text-large text-black">was built</span>
      <span class="text text-large text text-green">by students,</span>
      <span class="text text-large text text-green">for students.</span>
  </div>
  <p class="text text-black text-small mb-large">
    We're a service that helps students build confidence in their major, better their resume, increase their network, and gain exposure to a career path at any time of the year. 
  </p>

  <div id="what-is-unext" class="background gradient-green mb-medium">
    <div class="text text-medium mb-medium">
      What is Unext?
    </div>
    <img class="phone" alt="phone"
        [src]="cdn.toCDN('images/for-students/iPhone@3x.png')"
        [srcset]="cdn.toCDN('images/for-students/iPhone@2x.png 2x') + ', ' + cdn.toCDN('images/for-students/iPhone@3x.png 3x')"
       />
    <p class="text text-black text-normal">
      Unext was created to help students understand their career path of interest before pursuing a major and during their journey.
      Get connected anytime of the year no matter your stage in education with shadowing and volunteering opportunities. 
    </p>
    <p class="text text-black text-normal">
      Many students blindly apply to jobs and internships without understanding the position nor the culture.
      Expand your network and options, better your resume, get an edge! 
    </p>
  </div>
</section>
<section id="features" class="standard-padding">
  <div class="text text-green-muted text-medium text-center mb-medium ">
    Unext offers the following features to all students:
  </div>

  <div class="card-container">
    <div class="card mb-small">
      <div class="text text-green-muted text-medium">
        <img class="icon" [src]="cdn.toCDN('images/for-students/opportunities.png')" /> Our Mission
      </div>
      <p class="text text-black text-normal">
        To help all students understand their major, build confidence in their career, and graduate with a job that they are familiar with and love. 
      </p>
    </div>

    <div class="card mb-small">
      <div class="text text-green-muted text-medium">
        <img class="icon" [src]="cdn.toCDN('images/for-students/flow.png')" /> Shadowing
      </div>
      <p class="text text-black text-normal">
        Understand your major of interest and build confidence in your life after graduation. 
        Shadowing allows you to expand your network and options, while getting firsthand insight into a career path. 
      </p>
    </div>

    <div class="card mb-small">
      <div class="text text-green-muted text-medium">
        <img class="icon" [src]="cdn.toCDN('images/for-students/work.png')" /> Opportunities
      </div>
      <p class="text text-black text-normal">
        Invest in yourself and build a roadmap to success today!
      </p>
      <p class="text text-black text-normal">
        Shadowing - Volunteering - Internship - Employment - Others
      </p>
    </div>

    <div class="card mb-medium">
      <div class="text text-green-muted text-medium">
        <img class="icon" [src]="cdn.toCDN('images/for-students/resume.png')" /> Get Ahead
      </div>
      <p class="text text-black text-normal">
        Better your resume with shadowing and volunteering. Enter your employer preferences to save time in the applying process. 
        Get ahead of other candidates by utilizing the entire Unext experience! 
      </p>
    </div>
  </div>
  <img class="flair flair-1" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
  <img class="flair flair-2" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
</section>
<section id="sign-up" class="standard-padding">
  <a class="button btn-blue" [routerLink]="['/dashboard']">
    Sign up now
  </a>
</section>
<app-separator></app-separator>
<app-opportunities class="mb-small"></app-opportunities>

<section id="questions" class="standard-padding">
  <div class="text text-green-muted text-large text-center mb-medium">
    Have questions?
  </div>
  <div class="question-content">
    <div class="contact-faq">
      <p class="text text-black text-small mb-medium">
        Check out our Frequently Asked Questions section to learn more about Unext, or send us a message using our contact us page.
      </p>

      <a class="button orange outline mb-small mr-small" [routerLink]="['/faqs']">
        Visit Faqs
      </a>
      <a class="button btn-blue" [routerLink]="['/contact-us']">
        Contact us
      </a>
    </div>
    <img class="books" [src]="cdn.toCDN('images/for-students/bookshelf.png')" />
  </div>
  <img class="flair flair-3" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
  <img class="flair flair-4" [src]="cdn.toCDN('images/abstracts/green-abstract-shape.png')" />
</section>
<app-separator></app-separator>

<app-signup></app-signup>
