<app-progress-bar [splashScreen]="true" [value]="isLoading"></app-progress-bar>
<div class="bg-cream stepper-wrapper">
    <div class="bg-teal pl-medium pr-small py-large">
        <div class="text text-title text-lime mb-large">
            Request Opportunity
        </div>
        <app-stepper #stepper [steps]="steps" [(activeStep)]="activeStep"></app-stepper>
    </div>
    <div class="px-medium py-large">
        <div class="flex-columns justify-between">
            <div class="text text-title text-black">
                {{employer?.name}} Opportunity Request
            </div>
            <button class="ml-auto" [routerLink]="['/employers', urlName]">
                Return to Profile
            </button>
        </div>

        <form [formGroup]="requestForm" class="form pt-large pb-medium ng-untouched ng-pristine ng-invalid">
                <div [ngStyle]="{ 'display': steps?.[activeStep]?.id !== 'info' ? 'none' : 'block' }">
                

                <div class="flex-columns justify-between">


                    <div class="flex-rows mb-small" style="width: 100%">
                        <label class="form-label text text-black text-label">Opportunity Type</label>
                        <ng-select formControlName="OpportunityType" class="drop-down drop-down-button-blue"
                            [placeholder]="'Select Opportunity Type'" [items]="opportunityTypes"
                            (change)="selectOpportunityType($event)">
                        </ng-select>
                    </div>

                    <div class="flex-rows mb-small" style="width: 100%">
                        <label class="form-label text text-black text-label">Location</label>
                        <ng-select formControlName="Location" class="drop-down drop-down-button-blue"
                            [placeholder]="'Select Location'" [items]="locations">
                        </ng-select>
                    </div>
                </div>

                <div class="flex-columns justify-betweeen">
                    <div class="flex-rows mb-small" style="width: 100%">
                        <label class="form-label text text-black text-label">Industry</label>
                        <ng-select formControlName="Industry" class="drop-down drop-down-button-blue"
                            [placeholder]="'Select Industry'" [items]="industries">
                        </ng-select>
                    </div>

                    <div class="flex-rows mb-small" style="width: 100%">
                        <label class="form-label text text-black text-label">Field of Interest</label>
                        <ng-select formControlName="Interest" class="drop-down drop-down-button-blue"
                            [placeholder]="'Select Field of Interest'" [items]="interests">
                        </ng-select>
                    </div>

                </div>

                <div [ngStyle]="{ 'display': !displaySchedule ? 'none' : 'block' }" class="flex-rows mb-small mt-medium">
                    <label class="form-label text text-black text-label">Set Availability</label>
                    <app-week-scheduler id="schedule" [dateFormat]="false" (emitWeek)="setWeek()"
                        [unavailableDays]="['Sunday', 'Saturday']"></app-week-scheduler>
                </div>
            </div>

            <ng-container *ngIf="steps[activeStep].id === 'documentation'">
                <div class="col grid-thirds">

                    <div class="flex-rows resumes">
                        <label class="form-label text text-black text-label">
                            Select a Unext Resume or Upload
                        </label>
                        <ng-select formControlName="Resume" class="drop-down drop-down-button-blue"
                            [placeholder]="'Select Resume...'" [items]="resumes">
                        </ng-select>

                        <app-upload acceptType="resume" [entityType]="entityType.Resume"
                            [entityId]="employer?.id"
                            (filesUploaded)="resumeUploaded($event, 'upload-resume')">
                        </app-upload>
                        <span *ngIf="requestApplication.coverLetterURL?.length" class="chip chip-green">
                            Resume attached...
                        </span>
                    </div>
                    <div class="flex-rows mb-medium">
                        <label class="form-label text text-black text-label">Include a Cover Letter?</label>

                        <app-upload acceptType="resume" [entityType]="entityType.ApplicationCoverletter"
                            [entityId]="employer?.id"
                            (filesUploaded)="coverletterUploaded($event, 'upload-cover-letter')">
                        </app-upload>
                        <span *ngIf="requestApplication.coverLetterURL?.length" class="chip chip-green">
                            Coverletter attached...
                        </span>
                    </div>

                    <div class="flex-rows mb-medium">
                        <label class="form-label text text-black text-label">Include a Portfolio?</label>

                        <app-upload acceptType="resume" [entityType]="entityType.Portfolio" [entityId]="employer?.id"
                            (filesUploaded)="portfolioUploaded($event, 'upload-portfolio')">
                        </app-upload>

                        <span *ngIf="requestApplication.portfolioURL?.length" class="chip chip-green">
                            Portfolio attached...
                        </span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="steps[activeStep].id === 'overview'">

                <div *ngIf="opportunityType.value">
                    <div class="col grid-thirds">
                        <div>
                            <div class="form-label text-text-black text-label">Info:</div>
                        <div><label class="form-label text-text-black text-label">Opportunity Type: </label>{{opportunityType?.value?.label}}</div>
                        <div><label class="form-label text-text-black text-label">Location: </label>{{location?.value?.label}}</div>

                        <div><label class="form-label text-text-black text-label">Industry: </label>{{industry?.value?.label}}</div>
                        <div *ngIf="interest.value"><label class="form-label text-text-black text-label">Field of Interest: </label>{{interest?.value?.label}}</div>
                        </div>

                        <div *ngIf="displaySchedule">
                        <div class="form-label text-text-black text-label">Availabilty:</div>
                            <div><label class="form-label text-text-black text-label">Sunday: </label>{{getAvailabilityString(requestApplication.sundayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Monday: </label>{{getAvailabilityString(requestApplication.mondayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Tuesday: </label>{{getAvailabilityString(requestApplication.tuesdayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Wednesday: </label>{{getAvailabilityString(requestApplication.wednesdayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Thursday: </label>{{getAvailabilityString(requestApplication.thursdayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Friday: </label>{{getAvailabilityString(requestApplication.fridayAvailability)}}</div>
                            <div><label class="form-label text-text-black text-label">Saturday: </label>{{getAvailabilityString(requestApplication.saturdayAvailability)}}</div>
                        </div>
                        <div>
                            <div class="form-label text-text-black text-label">Documents:</div>
                            <div>
                                <label class="form-label text-text-black text-label">Resume:</label>{{resume?.value?.label}}
                            </div>
                            <div *ngIf="requestApplication.coverLetterURL">
                                <label class="form-label text-text-black text-label">Coverletter</label>{{coverLetterName}}
                            </div>
                            <div *ngIf="requestApplication.portfolioURL">
                                <label class="form-label text-text-black text-label">Portfolio:</label>{{portfolioName}}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="displayInvite">
                        <div class="mb-medium">

                            <label class="form-label" for="">Recommend to a friend</label>
                            <div class="flex-columns">

                                <div class="flex-rows" style="width: 100%">
                                    <label class="form-label" >Name</label>
                                    <input type="text" formControlName="Name"
                                        class="form-input form-input-rounded form-input-teal text">
                                </div>
                                <div class="flex-rows" style="width: 100%">
                                    <label class="form-label" >Email</label>
                                    <input type="text" formControlName="Email"
                                        class="form-input form-input-rounded form-input-teal text">
                                </div>
                            </div>

                        </div>

                        <div class="flex-columns justify-between">
                            <button (click)="cancelInvite()" class="button dark-green">Cancel</button>
                            <button (click)="sendInvite()" *ngIf="displayInvite"
                                class="button dark-green mr-small">Invite</button>

                        </div>


                    </div>
                    <button class="button dark-green text mt-small mb-medium" *ngIf="!displayInvite && !requestSubmitted"
                        (click)="displayInviteForm()">
                        Invite a Friend?
                    </button>
                </div>

                <div *ngIf="!opportunityType.value">
                    Required information is missing.
                </div>


            </ng-container>
        </form>

        <div class="flex-columns justify-between">

            <button type="button" class="text text-label btn btn-gray border border-black text"
                [disabled]="activeStep === 0" (click)="back()">
                Back
            </button>

            <button type="button" *ngIf="activeStep < steps.length - 1"
                class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid()"
                (click)="next()">
                Next
            </button>
            <button type="button" *ngIf="activeStep === steps.length - 1 && !displayInvite && opportunityType.value"
                class="text text-label btn lime-green border border-teal" [disabled]="!currentStepValid() || requestSubmitted" 
                (click)="submitRequest()">
                Send Request
            </button>
        </div>
    </div>

</div>